@import '../../../styles/mixins';
@import '../../../styles/extends';

.createCategory {
  
  .createCategory__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    h6 {
      font-size: 16px;
      font-weight: 700;
    }
    p {
      span {
        font-weight: 400;
      }
    }
  }

  .createSubCategory__category {
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgb(56 65 74 / 10%);  
  }

  .createCategory_category {
    // display: inline-block;
    // min-width: 620px;
    @include sm {
      min-width: 100%;
    }
  }

  .addCat {
    display: flex;
    justify-content: flex-end;
    button {
      @extend %btn;
      padding: 7px 20px;
      border-radius: 3px;
      margin-top: 10px;
    }
  }

}