@import '../../../../styles/extends';

.createSubCategory {

  h5 {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
  p {
    margin-bottom: 10px;
    font-size: 0.9rem;
    font-weight: 400;
    opacity: 0.8
  }

  .createSubCategory__create {
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgb(56 65 74 / 10%); 
    
    input {
      @extend %input;
      display: block;
      width: 100%;
      margin-bottom: 5px;
    }

    label {
      font-weight: 500;
      font-size: 0.9rem;
    }
    
    .createCategory__fields_thumbBox {
      height: 250px;
      border: 2px dashed var(--border-color);
      border-radius: 5px;
      margin-top: 10px;
      cursor: pointer;
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        i {
          font-size: 2.5rem;
          text-align: center !important;
        }
        p {
          font-weight: 400;
        }
      }
      .images {
        .image {
          width: 80px;
          height: 80px;
          border-radius: 5px;
          margin-bottom: 10px;
          position: relative;
          img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
          }
          i {
            position: absolute;
            top: -20%;
            right: -20%;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: var(--default-background);
            font-size: 0.9rem;
            display: grid;
            place-items: center;
            &:hover {
              background-color: var(--main-color);
              color: #fff;
            }
          }
        }
      }
    }

    .addNewOne {
      color: var(--main-color);
      font-weight: 600;
      cursor: pointer;
      margin-top: 10px;
    }

  }

  .btn {
    button {
      @extend %btn;
      margin-top: 10px;
      padding: 8px 20px;
      border-radius: 3px;
      width: 100%;
      text-align: center !important;
    }
  }

  .deleteSelected {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
    font-size: 0.95rem;
    span {
      margin: 0 10px;
      color: var(--secondary-color);
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .createSubCategory__edit {
    overflow-x: scroll;
    padding: 10px 0px;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgb(56 65 74 / 10%); 
    @include xs {
      padding: 10px;
    }

    h6 {
      margin-bottom: 10px;
      font-size: 1.1rem;
      padding: 5px 10px 0px 20px;
    }

    table {
      min-width: 550px;
      thead {
        background-color: var(--default-background);
        border-top: 1px solid var(--border-color);
        border-bottom: 1px solid var(--border-color);
        tr {
          border: none;
          th {
            border: none;
            padding: 15px 10px 15px;
            color: var(--text-color);
            font-weight: 450;
            opacity: 0.8;
            font-size: 0.95rem;
            cursor: pointer;
            span {
              display: flex;
              justify-content: space-between;
            }
          }
        }
      }
      tbody {
        tr {
          border: none;
          &:hover {
            background-color: var(--hover-color);
          }
          td {
            border: none;
            padding: 8px 10px 8px;
            color: var(--text-color);
            font-weight: 450;
            font-size: 0.9rem;
            padding-top: 10px;
            padding-bottom: 10px;
            cursor: pointer;
            .category {
              display: flex;
              align-items: center;
              img {
                width: 40px;
                height: 40px;
                object-fit: contain;
              }
              img.defaultSubCat {
                width: 30px;
                height: 30px;
              }
              span {
                margin: 0 10px;
              }
            }
            span.outStock {
              padding: 3px 5px;
              border-radius: 5px;
              color: var(--error-alert);
              background-color: var(--error-alert-light);
              font-size: 0.8rem;
            }
            span.rate {
              i {
                color: var(--alert);
                padding: 0 5px;
              }
            }
            button {
              background-color: var(--main-color-light);
              padding: 5px;
              font-size: 20px;
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 5px;
              &:hover {
                background-color: var(--main-color);
                color: #fff;
              }
            }
          }
        }
      }
    }

    .footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 20px 0px;
      border-top: 1px solid var(--border-color);
      p {
        color: var(--text-color);
        font-weight: 450;
        font-size: 0.95rem;
        margin: 0;
      }
    }

  }

}