@import '../../../styles/mixins';
@import '../../../styles/extends';

.linkSettings {
  padding: 20px;
  border-radius: 5px;
  animation: show 0.5s forwards 1;
  box-shadow: 0 1px 2px rgb(56 65 74 / 10%);
  @keyframes show {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  
  h6 {
    font-size: 1.2rem;
    margin-bottom: 30px;
    @include xs {
      font-size: 1.1rem;
    }
  }

  .linkSettings__field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .linkSettings__field_account {
      i {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        border: 1px solid var(--main-color);
        color: var(--main-color);
        text-align: center !important;
        line-height: 45px;
        // box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
        &:hover {
          background-color: var(--main-color);
          color: #fff;
        }
      }
      span {
        padding: 0 15px;
        font-weight: 500;
      }
    }

    .linkSettings__field_action {
      button {
        @extend %btn;
        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }

  }
}