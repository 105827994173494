@import './styles/mixins';
@import './styles/extends';

.page {
  padding-top: 100px;
  padding-left: 300px;
  width: 100%;
  z-index: 1;
  transition: all 0.2s ease-in-out;

  &.small {
    padding-left: 75px;
    width: 100%;
    @include md {
      padding-left: 0;
      width: calc(100% - 0px);
    }
  }

  &.noAuth {
    padding-top: 0;
    margin: 0;
    padding-left: 0;
    width: 100%;

    .pages {
      padding: 0;
    }

    @include xl {
      padding-left: 0;
      width: 100%;
    }
  
    @include md {
      padding-left: 0;
      width: 100%;
    }
  }

  &.ar {
    padding-left: unset;
    padding-right: 300px;
    width: 100%;

    &.small {
      padding-left: unset;
      padding-right: 75px;
      width: 100%;
      @include md {
        padding-right: 0;
        width: 100%;
      }
    }

    &.noAuth {
      padding-top: 0;
      padding-right: 0;
      width: 100%;

      @include xl {
        padding-left: 0;
        width: 100%;
      }
    
      @include md {
        padding-left: 0;
        width: 100%;
      }
    }

    @include xl {
      padding-right: 250px;
      width: 100%;
    }
  
    @include md {
      padding-right: 0;
      width: 100%;
    }

  }
  .pages {
    min-height: calc(100vh - 100px);
    position: relative;
    padding: 0px 40px 100px;
    @include xl {
      padding: 0px 20px 100px;
    }
    @include md {
      padding: 0px 20px 100px;
    }
    @include sm {
      padding: 20px 20px 100px 20px;
    }
    @include sm {
      padding: 10px 10px 100px 10px;
    }
  }

  @include xl {
    padding-left: 250px;
    width: 100%;
  }

  @include md {
    padding-left: 0;
    width: 100%;
  }

}

// GLOBAL SECTION STYLES
.section {
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgb(56 65 74 / 10%);
  transition: all 0.2s ease-in;
  &:hover {
    box-shadow: 0 5px 10px rgb(30 32 37 / 12%);
  }
}

// HANDLE POPUPS SHOW AND HIDE
.popup.active {
  display: block;
}

// GLOBAL DATE RANGE PICKER STYLES
.rdrDateRangeWrapper {
  background-color: var(--default-background);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  select {
    color: var(--text-color);
  }
  button {
    .rdrInRange, .rdrStartEdge, .rdrEndEdge {
      color: var(--main-color) !important;
    }
    span {
      color: var(--text-color);
    }
  }
}

// GLOBAL MATERIAL-UI DATE PICKER
.MuiFormControl-root {
  width: 100%;
}

.MuiCalendarPicker-root  {
  button.MuiButtonBase-root {
    padding: unset;
    width: 38px;
    height: 38px;
  }
}

// GLOBAL SLIDER STYLES
.MuiSlider-root {
  span {
    background-color: var(--main-color);
  }
  .MuiSlider-rail {
    background-color: rgba(#40739e, 0.3);
  }
}

// GLOBAL CHECKBOX STYLES
.subMenu {
  .MuiButtonBase-root {
    padding: 0 5px !important;
    width: 30px;
    height: 10px;
  }
}

// GLOBAL SWEET ALERT STYLES
.swal2-popup {
  background-color: var(--secondary-background) !important;
  color: var(--text-color) !important;
  .swal2-icon.swal2-warning {
    border-color: var(--error-alert) !important;
    color: var(--error-alert) !important;
  }
  h2 {
    font-size: 1.5rem
  }
  .swal2-html-container {
    font-size: 1.1rem
  }
  button.swal2-confirm {
    @extend %btn;
    box-shadow: unset !important;
    padding: 8px 20px;
  }

  button.swal2-cancel  {
    @extend %btn;
    background-color: var(--default-background);
    border: unset;
    text-decoration: none;
    padding: 10px 20px !important;
    box-shadow: unset !important;
    position: relative;
    top: -0.001rem;
  }
}