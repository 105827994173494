@import '../../../styles/mixins.scss';

.bestSellingTable, .bestSellingTable2 {
  margin-top: 20px;
  // height: 97%;
  overflow-x: scroll;
  padding: 10px 20px !important;

  &.bestSellingTable2 {
    overflow: hidden !important;
    padding: 0px !important;
    height: 93%;
  }

  .bestSellingTable__head, .bestSellingTable__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    p {
      color: var(--text-color);
      font-weight: 450;
    }
  }

  .bestSellingTable__head {
    button {
      color: var(--main-color);
      font-size: 0.9rem;
      padding: 3px 10px;
      border-radius: 5px;
      border: none;
      background-color: var(--main-color-light);
      cursor: pointer;
      i {
        margin: 0 5px;
      }
    }
  }

  .bestSellingTable__footer {
    margin-bottom: 0;
    margin-top: 20px;
    p {
      font-size: 0.95rem;
    }
    @include xl {
      p {
        font-size: 0.80rem;
      }
    }
    @include lg {
      p {
        font-size: 0.90rem;
      }
    }
    @include xs {
      display: block;
      p {
        margin-bottom: 10px;
        text-align: center !important;
      }
      .pagination {
        display: flex;
        justify-content: center;
      }
    }
  }

  table {
    min-width: 500px;
    tbody {
      tr {
        border-bottom: 1px solid var(--border-color);
        border-top: 1px solid var(--border-color);
        cursor: pointer;
        td {
          color: var(--text-color);
          font-weight: 450;
          border: none;
          padding: 8px;
          p {
            font-size: 0.9rem;
            opacity: 0.8;
          }
          .product {
            display: flex;
            align-items: center;
            img {
              width: 50px;
              height: 50px;
              object-fit: contain;
            }
            .product_info {
              padding: 0 8px;
            }
          }
          span.outStock {
            padding: 3px 5px;
            border-radius: 5px;
            color: var(--error-alert);
            background-color: var(--error-alert-light);
            font-size: 0.8rem;
          }
        }
        &:hover {
          background-color: var(--hover-color);
        }
      }
    }
  }

}