.wrapper {

  .dropLayer {
    background-color: transparent;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;

    span {
      pointer-events: none;
    }

    i {
      margin: 0 5px;
      font-size: 0.8rem;
      pointer-events: none;
    }
  }

  .chooseList {
    width: 150px;
    height: auto;
    box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.1);
    position: absolute;
    // top: 110%;
    right: 32px;
    z-index: 999;
    background-color: var(--background-light);
    padding: 10px 0;
    border-radius: 5px;
    border: 1px solid var(--border-color);
    animation: show 0.2s ease-out;
    display: none;

    &.show {
      display: block;
    }

    &.ar {
      right: unset;
      left: 0%;
    }

    li {
      padding: 5px 20px;
      font-size: 0.75rem;
      cursor: pointer;

      span {
        margin: 0 10px;
        font-size: 0.75rem;
      }

      i {
        font-size: 0.8rem;
      }

      &.iconNotFound {
        padding: 5px 10px;
      }

      &.active {
        background-color: var(--hover-color);
      }

      &:hover {
        background-color: var(--hover-color);
      }
    }

    @keyframes show {
      0% {
        transform: translateY(10px);
        opacity: 0;
      }

      100% {
        transform: translateY(0px);
        opacity: 1;
      }
    }
  }
}