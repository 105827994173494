.userPreview {
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgb(56 65 74 / 10%);

  .userPreview__userPart {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--border-color);
    overflow: hidden;
    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }

    .userPreview__userPart_userInfo {
      margin: 0 15px;
      h6 {
        font-size: 1rem;
        font-weight: 600;
      }
      p {
        font-size: 0.9rem;
        opacity: 0.8;
      }
    }
  }

  .userPreview__transactions {
    padding: 20px 0 15px;
    border-bottom: 1px solid var(--border-color);
    display: grid;
    justify-content: center;
    p {
      text-transform: uppercase;
      font-size: 0.9rem;
      opacity: 0.8;
      text-align: center !important;
    }
    span {
      font-size: 1.5rem;
      color: var(--main-color);
      font-weight: 600;
      text-align: center !important;
    }
  }

  .userPreview__links {
    padding-top: 20px;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      padding: 8px 15px;
      border-radius: 5px;
      i {
        font-size: 0.8rem;
        opacity: 0.8;
        &.ar {
          transform: rotate(180deg);
        }
      }
      &.selected {
        // color: var(--main-color);
        background-color: var(--default-background);
      }
    }
  }

  .topSkeleton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 10px;
    .stripe {
      width: 80% !important;
      margin-top: 10px;
    }
  }
}