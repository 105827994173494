@import '../../../styles/extends';

.allCoupons {
  .allCoupons__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    h6 {
      font-size: 16px;
      font-weight: 700;
    }
    p {
      span {
        font-weight: 400;
      }
    }
  }

  .allCoupons__tabel {
    position: relative;
  }

  .allCoupons__table_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px dashed var(--border-color);
    padding: 15px 20px;
    h6 {
      font-size: 1rem;
    }
    button {
      @extend %btn;
      padding: 8px 20px;
    }
  }
}