@import '../../../styles/mixins';
@import '../../../styles/extends';

.ordersTable {
  overflow-x: scroll;
  box-shadow: 0 1px 2px rgb(56 65 74 / 10%);

  .orders__table_options {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 1rem;
    align-items: center;
    padding: 20px;
    .search {
      position: relative;
      i {
        position: absolute;
        top: 22%;
        left: 15px;
        opacity: 0.6;
      }
      input {
        padding-left: 40px;
      }
      &.ar {
        i {
          left: unset;
          right: 15px;
        }
        input {
          padding-left: unset;
          padding-right: 40px;
        }
      }
    }
    div {
      grid-column: span 4;
      @include sm {
        grid-column: span 6;
      }
      @include xs {
        grid-column: span 12;
      }
    }
    input {
      @extend %input;
      height: 42px;
      width: 100%;
    }

    .date {
      & > div {
        border: 1px solid var(--border-color);
        padding-right: 10px;
        background-color: var(--default-background);
        border-radius: 5px;
        height: 42px;
        input {
          border: none;
          padding: 0 20px;
          height: 40px;
        }
      }
      svg {
        color: var((--text-color));
      }
      fieldset {
        border: none;
      }
      &.ar {
        padding-right: 0;
        padding-left: 10px;
        div {
          padding-right: 0;
        }
      }
    }
  }

  .orders__table_filters {
    padding: 0 20px;
    display: flex;
    flex-wrap: wrap;
    @include xs {
      justify-content: center;
      p {
        margin-bottom: 10px;
      }
    }
    p {
      padding: 5px 12px;
      font-size: 0.95rem;
      cursor: pointer;
      span {
        margin: 0 5px;
      }
      &.selected {
        color: var(--main-color);
        border-bottom: 2px solid var(--main-color);
        font-weight: 600;
      }
    }
  }

  .deleteSelected {
    display: flex;
    justify-content: flex-end;
    font-size: 0.95rem;
    span {
      margin: 0 10px;
      color: var(--secondary-color);
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .ordersTable__table {
    table {
      min-width: 1000px;
      thead {
        border-top: 1px solid var(--border-color);
        background-color: var(--default-background);
        tr {
          border: none;
          th {
            border: none;
            padding: 15px 10px 15px;
            color: var(--text-color);
            font-weight: 450;
            opacity: 0.8;
            font-size: 0.95rem;
            cursor: pointer;
            span {
              display: flex;
              justify-content: space-between;
            }
          }
        }
      }
      tbody {
        tr {
          border: none;
          cursor: pointer;
          &:hover {
            background-color: var(--hover-color);
          }
          td {
            border: none;
            padding: 8px 10px 8px;
            color: var(--text-color);
            border-top: 1px solid var(--border-color);
            border-bottom: 1px solid var(--border-color);
            font-weight: 450;
            font-size: 0.8rem;
            .product, .customer {
              display: flex;
              align-items: center;
              img {
                width: 50px;
                height: 50px;
                object-fit: contain;
              }
              .info {
                padding: 0 10px;
                p {
                  font-size: 0.9rem;
                  margin: 0;
                  &.category {
                    opacity: 0.8;
                    font-size: 0.85rem;
                  }
                }
              }
            }
            .customer {
              img {
                width: 35px;
                height: 35px;
                border-radius: 50%;
                object-fit: cover;
              }
            }
            span.status {
              padding: 2px 10px;
              border-radius: 5px;
              &.Processing {
                background-color: var(--alert-light);
                color: var(--alert);
              }
              &.Completed {
                background-color: var(--success-light);
                color: var(--success);
              }
              &.Refunded {
                background-color: var(--error-alert-light);
                color: var(--error-alert);
              }
              &.Cancelled {
                background-color: var(--error-alert-light);
                color: var(--error-alert);
              }
            }
            button {
              background-color: var(--main-color-light);
              padding: 5px;
              font-size: 20px;
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 5px;
              &:hover {
                background-color: var(--main-color);
                color: #fff;
              }
            }
          }
        }
      }
    }
  }

  .ordersTable__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    p {
      color: var(--text-color);
      font-weight: 450;
      font-size: 0.95rem;
    }
    @include xs {
      flex-direction: column;
      p {
        margin-bottom: 5px;
      }
    }
  }
}