@import '../../styles/extends.scss';

.notFound {
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  img {
    width: 200px;
    height: 200px;
    object-fit: contain;
    opacity: 0.8;
  }
  h4 {
    width: 300px;
    text-align: center !important;
    font-size: 1.1rem;
  }

  button {
    @extend %btn;
    margin-top: 10px;
  }
}