// IMPORTING GLOBAL VARIABLES AND MIXINS
@import '../../../styles/mixins';
@import '../../../styles/extends';

.overlay {
  @keyframes showModal {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  animation: showModal 0.3s forwards ease-in-out 1;
  .area {
    background-color: var(--secondary-background);
    width: 500px;
    height: auto;
    max-height: 90%;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    position: relative;
    animation: showModal 0.5s forwards ease-in-out 1;
    .area__wrapper {
      max-height: 90vh;
      overflow-y: scroll;
      padding: 30px;
    }
    @include lg {
      width: 500px;
    }
    @include md {
      width: 500px;
    }
    @include sm {
      width: 500px;
    }
    @include xs {
      width: 90%;
    }
    .close {
      position: absolute;
      top: -15px;
      right: -15px;
      width: 30px;
      height: 30px;
      cursor: pointer;
      background-color: #000;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.2s ease-in-out !important;
      &:hover {
        transform: rotate(90deg) !important;
      }
      &.ar {
        right: unset;
        left: -15px;
      }
    }

    .area__content {
      h3 {
        text-align: center !important;
        margin-bottom: 20px;
        @include xs {
          font-size: 20px;
        }
      }

      .area__content_field {

        label {
          font-weight: bold;
          font-size: 15px;
          @include xs {
            font-size: 14px;
          }
        }

        input {
          width: 100%;
          padding: 8px 15px;
          border: 1px solid var(--border-color);
          border-radius: 5px;
          margin-bottom: 15px;
          margin-top: 3px;
          outline: none;
          background-color: var(--default-background);
          @include xs {
            padding: 5px 10px;
          }
        }

      }

      .actions {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
        button {
          @extend %btn;
          padding: 8px 20px;
        }
        button:first-of-type {
          margin: 0 10px;
          background-color: var(--hover-color);
          border-color: var(--border-color);
          color: var(--text-color);
        }
      }
    }

  }
}