.productReviews {
  margin-top: 20px;
  .productReviews__ratingBox {
    .productReviews__ratingBox_rating {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 15px;
      border-radius: 5px;
      span {
        span {
          width: 20px;
        }
      }
    }

    & > p {
      text-align: center !important;
      margin-top: 10px;
    }

    .productReviews__ratingBox_rates {
      .rate {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        .progress {
          height: 5px;
          width: 60%;
          border-radius: 5px;
          background-color: var(--default-background);
          position: relative;
          span {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            // width: 45%;
            background-color: var(--main-color);
            border-radius: 5px;
          }
        }
      }
    }
  }

  .productReviews__reviews {
    .productReviews__reviewsBox {
      min-height: 200px;
      max-height: 300px;
      overflow-y: scroll;
      .productReviews__reviewsBox_review {
        border: 2px dashed var(--border-color);
        padding: 20px 10px;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        margin: 0 10px 10px 0;
        .user {
          display: flex;
          img {
            width: 40px;
            height: 40px;
            border-radius: 10px;
          }
          .userInfo {
            padding: 0 10px;
          }
        }
        .side {
          p {
            font-size: 0.8rem;
            &.stars {
              background-color: var(--main-color);
              display: inline-block;
              padding: 2px 10px;
              border-radius: 5px;
              color: #fff;
              margin-bottom: 5px;
              min-width: 30px;
              i {
                font-size: 0.7rem;
              }
            }
          }
        }
      }
    }
  }
}