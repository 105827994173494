@import '../../styles/mixins.scss';

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 300px;
  transition: all 0.2s ease-in-out;
  border-right: 1px solid var(--border-color);
  z-index: 999;

  &.ar {
    left: unset;
    right: 0;
    border-left: 1px solid var(--border-color);
    border-right: unset;

    @include md {
      left: unset;
      right: -300px;
    }

  }

  @include xl {
    width: 250px;
  }

  @include md {
    left: -300px;
  }

  .sidebar__head {
    height: 70px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border-color);

    .sidebar__head_content {
      display: flex;
      align-items: center;
      margin: 0 10px;

      h4 {
        font-size: 1.7rem;
        font-weight: 500;
        transition: opacity 0.1s ease-in-out;
        color: var(--main-color);
        cursor: pointer;
      }

      img {
        width: 40px;
        height: 40px;
        margin: 0 8px;
        cursor: pointer;
      }
    }
  }

  &.small {
    transition: all 0.3s ease;
    width: 75px;

    .sidebar__head {
      .sidebar__head_content {
        margin: 0;

        h4 {
          opacity: 0;
        }
      }
    }

    &:hover {
      width: 300px;

      &.sidebar__list {
        .link {
          justify-content: flex-start;

          .icon i {
            width: 40px;
          }

          &>i {
            opacity: 1;
            display: inline-block;
          }

          .label {
            opacity: 1;
            display: inline-block;
          }
        }
      }
    }
  }

  .sidebar__body {
    height: 88vh;
    // overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;
    // overflow-x: hidden;
    // -webkit-overflow-y: auto;
    // overflow-y: auto;
    // overflow-x: hidden !important;

    // /* Handle */
    &::-webkit-scrollbar-thumb {
      opacity: 0.2 !important;
      // background-color: var(--scroll-color);
      background-color: transparent;
    }
  }

}