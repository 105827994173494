@import '../../../styles/extends';

.chatHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .chatHead__user {
    display: flex;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    p {
      transform: translateY(5px);
    }
    p, span {
      margin: 0 10px;
      font-weight: 500;
    }
    span {
      font-size: 0.8rem;
      opacity: 0.8;
    }
    @include xs {
      margin-bottom: 10px;
    }
  }

  .btns {
    display: flex;
    align-items: center;
    gap: 10px;
    button {
      @extend %btn;
    }
    @include sm {
      button {
        padding: 3px 10px;
        font-size: 0.8rem;
      }
    }
    @include xs {

    }
  }

}