@import '../../../styles/extends';

.createCoupon {
  .createCoupon__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    h6 {
      font-size: 16px;
      font-weight: 700;
    }
    p {
      span {
        font-weight: 400;
      }
    }
  }

  .gridsWarpper {
    @include sm {
      flex-direction: column-reverse;
    }
  }
  
  .createCoupon__information {
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgb(56 65 74 / 10%);
    h6 {
      font-size: 1rem;
      margin-bottom: 20px;
    }

    .createCoupon__information_field {
      margin-bottom: 10px;
      label {
        display: block;
        font-size: 0.9rem;
        font-weight: 500;
        margin: 0;
        &.disabled {
          opacity: 0.5;
        }
      }
      input {
        @extend %input;
        width: 100%;
      }
      &.radioArea {
        span {
          font-size: 0.9rem;
          margin: 2px 3px;
          padding: 0;
          span {
            transform: scale(0.8);
          }
        }
      }
      .switch {
        display: flex;
        align-items: center;
        justify-content: space-between;
        input {
          width: 100%;
          @include lg {
            width: 100%;
          }
          @include xs {
            width: 90%;
          }
          &:disabled {
            opacity: 0.7;
            cursor: not-allowed;
          }
        }
        div {
          display: flex;
          flex-direction: row;
          align-items: center;
          label {
            margin: 0 1px;
            margin: 0;
          }
          i {
            margin: 0 5px;
          }
        }
      }
    }
  }

  .btn {
    display: flex;
    justify-content: flex-end;
    button {
      @extend %btn;
      margin-top: 10px;
      padding: 7px 30px;
    }
  }

  .createCoupon__status, .createCoupon__date {
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    box-shadow: 0 1px 2px rgb(56 65 74 / 10%);
    @include sm {
      margin-bottom: 0;
    }
    h6 {
      font-size: 1rem;
      margin-bottom: 10px;
    }
    
    p {
      margin-bottom: 10px;
    }
    
    .createCoupon__status_field, .createCoupon__date_field {
      margin-bottom: 10px;
      label {
        display: block;
        font-size: 0.9rem;
        font-weight: 500;
        margin: 0;
      }
      input {
        @extend %input;
        width: 100%;
      }
      span {
        font-size: 0.9rem;
        margin: 2px 3px;
        padding: 0;
        span {
          transform: scale(0.8);
        }
      }
      label {
        display: flex;
      }
    }
    .createCoupon__date_field {
      & > div {
        border: 1px solid var(--border-color);
        padding-right: 10px;
        background-color: var(--default-background);
        border-radius: 5px;
        height: 42px;
        input {
          border: none;
          padding: 0 20px;
          height: 40px;
        }
      }
      svg {
        color: var((--text-color));
      }
      fieldset {
        border: none;
      }
      &.ar {
        padding-right: 0;
        padding-left: 10px;
        div {
          padding-right: 0;
        }
      }

    }
  }
}