.accordion_link {
  transition: all 0.3s ease-in-out;
  position: relative;

  .link {
    padding: 3px 10px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 1rem;
    position: relative;
    &.active {
      background-color: var(--default-background);
      padding: 5px 10px;
      box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.05);
      & > i {
        top: 20%;
      }
    }

    .icon {
      pointer-events: none;
      
      i {
        font-size: 1.3rem;
        display: flex;
        align-items: center;
      }

    }

    .label {
      pointer-events: none;
      transition: opacity 0.1s ease-in-out;
      font-size: 0.85rem;
    }

    & > i {
      position: absolute;
      top: 10%;
      right: 10px;
      transition: all 0.2s ease-in-out;
      pointer-events: none;
      font-size: 0.8rem;
      &.ar {
        right: unset;
        left: 20px;
      }
    }
  }

  .subMenu {
    cursor: pointer;
    height: auto;
    overflow: hidden;
    max-height: 0;
    transition: all 0.3s ease-in-out;
    position: relative;
    z-index: 9;

    .dropLayer {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: transparent;
      z-index: -1;
    }

    li {
      padding: 10px 0px 0px;
      font-size: 0.9rem;
      opacity: 0.9;
      display: flex;
      align-items: center;
      cursor: pointer;
      &.choosed {
        color: var(--main-color);
        font-weight: bold;
      }
      label {
        cursor: pointer;
      }
      svg {
        width: 18px;
        height: 18px;
      }
      &:last-of-type {
        padding: 10px 0px 15px;
      }
      &:hover {
        color: var(--main-color);
      }
      i {
        margin: 0 5px;
        font-size: 0.8rem;
      }
    }
  }

  &.showSub {
    .link {
      & > i {
        transform: rotate(180deg);
      }
    }
  }

  &.smallAccordion {
    .link {
      .label {
        font-size: 0.9rem;
      }
    }
    .subMenu {
      li {
        font-size: 0.85rem;
        padding-top: 5px;
      }
    }
  }

}