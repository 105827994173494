@import '../../styles/mixins.scss';

.navbar {
  padding: 0 35px 0 35px;
  position: fixed;
  top: 0;
  z-index: 999;
  width: calc(100% - 300px);
  box-shadow: 0 0.75rem 0.5rem rgba(65, 83, 104, 0.01);
  transition: all 0.2s ease-in-out;
  border-bottom: 1px solid var(--border-color);
  height: 70px;

  &.small {
    width: calc(100% - 75px);
    @include md {
      width: calc(100% - 0px);
    }
  }

  @include xl {
    width: calc(100% - 250px);
    padding: 0 0px 0 10px;
  }

  @include md {
    width: 100%;
  }

  @include xs {
    padding: 0;
  }

  .navbar__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    .navbar__content_icons {
      display: flex;
      align-items: center;
      @include md {
        display: block;
      }
      div {
        margin: 0 5px;
        display: flex;
        i {
          font-size: 20px;
          width: 40px;
          height: 45px;
          background-color: transparent;
          padding: 10px;
          margin: 0 5px;
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        &.smallBarIcon {
          display: flex;
          @include md {
            display: none;
          }
        }
        &.mobBar {
          display: none;
          width: 50px;
          height: 45px;
          margin-bottom: 3px;
          @include md {
            display: flex;
          }
        }

        input {
          width: 400px;
          height: 45px;
          outline: none;
          padding: 5px 20px;
          border: none; 
          background-color: transparent;
          border-right: 1px solid var(--border-color);
          &.ar {
            border-right: unset;
            border-left: 1px solid var(--border-color);
          }
          @include lg {
            width: 300px;
          }
          @include md {
            display: none;
          }
        }
        button {
          width: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 45px;
          outline: none;
          padding: 5px 20px;
          border: none;
          background-color: transparent;
          cursor: pointer;
          &:hover {
            background-color: var(--main-color);
            color: #fff;
          }
          @include lg {
            width: 70px;
          }
          @include md {
            display: none;
          }
        }

      }
    }

    .navbar__content_actions {
      display: flex;
      align-items: center;

      .notifications {
        position: relative;
        cursor: pointer;

        @keyframes jump-shaking {
          0% { transform: translateX(0) }
          25% { transform: translateY(0px) }
          35% { transform: rotate(10deg) }
          55% { transform: rotate(-10deg) }
          65% { transform: rotate(10deg) }
          75% { transform: rotate(-10deg) }
          100% { transform: rotate(0) }
        }

        .notificationIcon {
          width: 30px;
          height: 30px;
          font-size: 20px;
          margin: 0 5px;
          animation: jump-shaking 1.5s cubic-bezier(.36,.07,.19,.97) both infinite;
        }

        .num {
          background-color: var(--main-color);
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          position: absolute;
          top: -25%;
          right: 5%;
          font-size: 0.8rem;
        }

      }

      .user {
        position: relative;
        margin-left: 20px;
        border-bottom: 1px solid var(--border-color);

        &.ar {
          margin-left: unset;
          margin-right: 20px;
          @include sm {
            margin-right: 20px;
            margin-left: 0px;
          }
        }

        @include sm {
          margin-right: 0px;
          margin-left: 20px;
        }

        .user__thumb {
          display: flex;
          align-items: center;
          cursor: pointer;
          border: 2px solid transparent;
          position: relative;
          z-index: 3;
          padding: 5px 10px;
          height: 70px;
          img, .imageAlt {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            margin-right: 10px;
            &.ar {
              margin-right: unset;
              margin-left: 10px;
            }
          }

          .imageAlt {
            background-color: var(--main-color);
            display: grid;
            place-items: center;
            color: #fff;
            font-size: 1rem;
            text-transform: uppercase;
          }

          p {
            font-size: 0.78rem;
            font-weight: 400;
            position: relative;
            top: 6px;
          }

          span {
            font-size: 0.70rem;
            font-weight: 300;
            position: relative;
            top: -2px;
          }

          @include xs {
            padding: 5px 10px;
            img {
              width: 35px;
              height: 35px;
            }
            p {
              font-size: 0.7rem;
            }
          }
        }

      }

      // GLOBAL STYLING FOR USER AND NOTIFICATIONS DROPDOWNS
      .user__popup, .notificationsDropdown {
        .layedDrop {
          position: fixed;
          height: 100vh;
          top: 0;
          left: 0;
          width: 100%;
          z-index: 2;
          background-color: transparent;
        }

        .popup {  
          animation: show 0.1s ease-in-out 1;
          background-color: var(--background-light);
          border: 1px solid var(--border-color);
          @keyframes show {
            0% {
              transform: translateY(10px);
              opacity: 0;
            }
            100% {
              transform: translateY(0px);
              opacity: 1;
            }
          }
          padding: 10px 0;
          position: absolute;
          top: 105%;
          right: 0%;
          box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
          width: 200px;
          z-index: 3;
          border-radius: 5px;
          &.ar {
            right: unset;
            left: 0%;
          }

          div {
            padding: 8px 15px;
            cursor: pointer;
            font-size: 0.9rem;
            &:hover {
              background-color: var(--hover-color);
            }
            i {
              margin: 0 10px;
            }
          }

        }

      }

      .notificationsDropdown {
        .notificationsHead {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid var(--border-color);
          background-color: var(--secondary-background) !important;
          span {
            color: var(--main-color);
            font-weight: 600;
            font-size: 0.9rem;
            &:hover {
              text-decoration: underline;
            }
            i {
              width: 5px !important;
              height: 5px !important;
              font-size: 0.8rem;
            }
            @include xs {
              font-size: 0.8rem;
            }
          }
        }

        .popup {
          padding: 0;
          width: 400px;
          overflow-y: scroll;
          min-height: 300px;
          max-height: 400px;
          z-index: 9999;
          right: -100px;
          &.ar {
            right: -150px;
          }
          @include sm {
            right: -150px !important;
          }
          @include xs {
            right: -50px !important;
            width: 300px;
          }
        }
      }

    }

  }

}