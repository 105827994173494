@import '../../../styles/mixins';
@import '../../../styles/extends';

.returnedItem {
  border: 1px solid var(--border-color);
  border-radius: 5px;
  margin-bottom: 10px;

  .head {
    background-color: var((--default-background));
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px 5px 0 0;
    @include xs {
      display: block;
      .head__part {
        margin-bottom: 10px;
      }
    }
    .head__part {
      display: flex;
      align-items: center;
      gap: 2rem;
      p {
        font-size: 14px;
        &.top {
          font-size: 13px;
          font-weight: 600;
        }
        &.view {
          font-size: 13px;
          font-weight: 600;
          color: var(--main-color);
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      &.includeView {
        @include xs {
          display: block;
          .view {
            margin-top: 10px;
          }
        }
      }
    }

  }

  .body {
    padding: 20px;
    h6 {
      margin-bottom: 20px;
      font-size: 18px;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }

    .body__order {
      display: grid;
      align-items: center;
      justify-content: space-between;
      grid-template-columns: repeat(12, 1fr);
      border-radius: 5px;
      padding: 10px;
      opacity: 0.8;
      margin-bottom: 3px;
      @include md {
        display: block;
      }

      .body__order_img {
        display: flex;
        align-items: center;
        grid-column: span 10;
        img {
          width: 80px;
          height: 80px;
          margin-right: 20px;
          object-fit: contain;
          &.ar {
            margin-right: unset;
            margin-left: 20px;
          }
          @include lg {
            margin-right: 20px;
          }
          @include md {
            margin-right: 20px;
            width: 130px;
          }
        }
        h6 {
          margin: 0;
          font-size: 1rem;
          @include xs {
            font-size: 17px;
          }
        }
        p {
          margin-bottom: 10px;
          font-size: 0.9rem;
          @include xs {
            font-size: 15px;
          }
        }
        button {
          @extend %btn;
          font-size: 0.8rem;
          &:hover {
            background-color: transparent;
            border-radius: 5px;
            color: var(--main-color);
            border-color: var(--main-color);
          }
        }
      }

      .body__order_actions {
        display: flex;
        flex-direction: column;
        grid-column: 10 / span 6;
        @include xl {
          grid-column: 9 / span 6;
        }
        @include sm {
          margin-top: 10px;
        }
        button {
          margin: 3px 0px;
          @extend %mainBtn;
          font-size: 13px;
          max-width: 300px;
          border-radius: 8px;
          &.review {
            background-color: transparent;
            color: var(--main-color);
          }
          &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
            &:hover {
              background-color: var(--main-color);
              color: #fff;
            }
          }
        }
      }

      &.selected {
        opacity: 1;
        background-color: var(--default-background)
      }

      input {
        @extend %input;
        width: 100px;
      }

    }
  }
}