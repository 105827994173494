@import '../../../styles/mixins.scss';

.allCategories {
  @include sm {
    max-width: 100%;
    margin: 0;
  }
  .allCategories__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    h6 {
      font-size: 16px;
      font-weight: 700;
    }
    p {
      span {
        font-weight: 400;
      }
    }
  }
}