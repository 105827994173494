@import '../../../styles/mixins';

.smallTopAnalytics {
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1rem;

  .smallTopAnalytics__section {
    background-color: var(--default-background);
    grid-column: span 3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include lg {
      grid-column: span 6;
    }
    @include md {
      grid-column: span 6;
    }
    @include sm {
      grid-column: span 6;
    }
    @include xs {
      grid-column: span 12;
    }
    .smallTopAnalytics__section_part {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      &:first-of-type {
        margin-bottom: 5px;
      }
      p {
        font-size: 0.9rem;
        opacity: 0.8;
        text-transform: uppercase;
      }
      span {
        color: var(--secondary-color);
        font-weight: 600;
      }
      .price {
        font-size: 1.3rem;
        margin-top: 10px;
        font-weight: 550;
      }
      .item {
        font-size: 1.1rem;
        margin-top: 10px;
        font-weight: 550;
      }
      a {
        font-size: 0.9rem;
        color: var(--main-color);
        text-decoration: underline;
      }
      .icon {
        width: 50px;
        height: 45px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.3rem;
      }
    }
    &:nth-of-type(odd) {
      .smallTopAnalytics__section_part {
        span {
          color: var(--main-color);
          font-weight: 600;
        }
        .icon {
          background-color: rgba(#40739e, 0.3);
          color: var(--main-color);
        }
      }
    }
    &:nth-of-type(even) {
      .smallTopAnalytics__section_part {
        span {
          color: var(--secondary-color);
          font-weight: 600;
        }
        .icon {
          background-color: rgba(#ff7300, 0.3);
          color: var(--secondary-color);
        }
      }
    }
  }
}