@import '../../styles/extends';

.logs {
  .logs__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    h6 {
      font-size: 1rem;
      font-weight: 700;
    }
    p {
      span {
        font-weight: 400;
      }
    }
  }

  .logs__body {
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgb(56 65 74 / 10%);

    .dateClearFilter {
      display: flex;
      justify-content: flex-end;
      span {
        cursor: pointer;
        color: var(--secondary-color);
      }
    }
    
    .logs__body__filters {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid var(--border-color);
      div {
        label {
          font-size: 0.9rem;
          font-weight: 500;
        }
        input {
          @extend %input;
          padding: 10px 15px;
          width: 100%;
        }
        i {
          top: 30%;
        }
      }
      @include xs {
        flex-direction: column;
      }
    }

    .datePicker {
      div {
        width: 100%;
      }
      .datePicker__text {
        h6 {
          font-size: 17px;
        }
        p {
          font-size: 15px;
          opacity: 0.8;
        }
      }
      .datePicker__date {
        position: relative;
        display: flex;
        align-items: center;
        input {
          background-color: var(--default-background);
          color: var(--text-color);
          border-radius: 5px 0 0 5px;
          border: none;
          box-shadow: 0 0 2px rgb(0 0 0 / 5%);
          padding: 10px 15px;
          cursor: pointer;
          outline: none;
          font-size: 14px;
          min-height: 42px;
        }
    
        & > i {
          padding: 8px 15px;
          cursor: pointer;
          outline: none;
          font-size: 16px;
          background-color: var(--main-color);
          color: #fff;
          border-radius: 0 5px 5px 0;
          box-shadow: 0 0 2px rgb(0 0 0 / 5%);
          min-height: 42px;
        }
    
        button {
          border-radius: 5px;
          svg {
            color: #fff;
          }
        }
        fieldset {
          border-color: transparent !important;
          box-shadow: 0 1px 2px rgb(56 65 74 / 10%);
        }
        &.ar {
          input {
            border-radius: 0 5px 5px 0;
          }
          & > i {
            border-radius: 5px 0 0 5px;
          }
        }
      }
    }
    
    .search {
      position: relative;
      i {
        position: absolute;
        top: 21% !important;
        left: 15px;
        opacity: 0.6;
      }
      input {
        padding-left: 40px !important;
      }
      &.ar {
        i {
          left: unset;
          right: 15px;
        }
        input {
          padding-left: unset;
          padding-right: 40px;
        }
      }
    }

    .paginationWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;
      @include xs {
        flex-direction: column;
        p {
          margin-bottom: 5px;
        }
      }
    }
  }
}