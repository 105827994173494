@import '../../../styles/mixins';

.personalInfoTab {
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgb(56 65 74 / 10%);
  animation: show 0.3s ease-in-out;
  @keyframes show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .personalInfoTab__head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    h6 {
      font-size: 1.1rem;
    }
    p {
      font-size: 0.9rem;
      opacity: 0.8;
    }
    i {
      width: 40px;
      height: 40px;
      background-color: var(--default-background);
      display: grid;
      place-items: center;
      border-radius: 5px;
      cursor: pointer;
      &:hover {
        background-color: var(--main-color);
        color: #fff;
      }
    }
  }

  .personalInfoTab__info {
    .personalInfoTab__info_field {
      display: flex;
      gap: 1rem;
      padding: 10px 0;
      @include xs {
        display: block;
        padding: 0;
        div {
          width: 100% !important;
          p {
            margin-bottom: 10px;
          }
        }
      }
      div {
        width: 50%;
        span {
          font-size: 0.9rem;
          font-weight: 500;
        }
        p {
          background-color: var(--default-background);
          padding: 8px 15px;
          border-radius: 5px;
        }
      }
    }
  }
}