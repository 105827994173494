@import '../../../styles/mixins';
@import '../../../styles/extends';

.field {
  .field__input {
    position: relative;
    input {
      @extend %form-element;
      &.invalid {
        border: 1px solid var(--error-alert) !important;
      }
    }

    i {
      position: absolute;
      top: 38%;
      right: 20px;
      color: var(--main-color);
      &.alert {
        color: var(--error-alert);
      }
      &.ar {
        left: 20px;
        right: unset;
      }
    }
  }
  
  .errorMessage {
    color: var(--error-alert);
    font-size: 15px;
    @include xs {
      font-size: 13px;
    }
  }
}