.modeSwitch {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(247, 247, 247);
  -moz-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  i {
    font-size: 20px;
  }

  &:hover {
    background-color: var(--main-color);
    color: #fff;
  }

}