@import '../../../styles/mixins';
@import '../../../styles/extends';

.allAds__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  h6 {
    font-size: 16px;
    font-weight: 700;
  }
  p {
    span {
      font-weight: 400;
    }
  }
}

.deleteSelected {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
  font-size: 0.95rem;
  span {
    margin: 0 10px;
    color: var(--secondary-color);
    text-decoration: underline;
    cursor: pointer;
  }
}

.allAds {
  border-radius: 5px;
  box-shadow: 0 1px 2px rgb(56 65 74 / 10%);
  .allAds__table {
    padding: 10px 0;
    border-radius: 5px;
    .table {
      overflow-x: scroll;
    }

    .allAds__table_head {
      display: flex;
      justify-content: space-between;
      @include xs {
        display: block
      }

      .btn {
        button {
          @extend %btn;
          padding: 7px 20px;
          border-radius: 3px;
          margin-bottom: 10px;
        }
      }

      .allProductsTable__head_search {
        position: relative;
        margin-bottom: 10px;
        input {
          @extend %input;
          padding: 7px 15px 7px 35px;
        }
        i {
          position: absolute;
          top: 20%;
          left: 10px;
          opacity: 0.7;
        }
      }
    }

    table {
      min-width: 650px;
      thead {
        background-color: var(--default-background);
        border-top: 1px solid var(--border-color);
        border-bottom: 1px solid var(--border-color);
        tr {
          border: none;
          th {
            border: none;
            padding: 15px 10px 15px;
            color: var(--text-color);
            font-weight: 450;
            opacity: 0.8;
            font-size: 0.95rem;
            cursor: pointer;
            span {
              display: flex;
              justify-content: space-between;
            }
          }
        }
      }
      tbody {
        tr {
          border: none;
          &:hover {
            background-color: var(--hover-color);
          }
          td {
            border: none;
            padding: 8px 10px 8px;
            color: var(--text-color);
            font-weight: 450;
            font-size: 0.9rem;
            padding-top: 10px;
            padding-bottom: 10px;
            .category {
              display: flex;
              align-items: center;
              img {
                width: 50px;
                height: 45px;
                object-fit: contain;
              }
              span {
                margin: 0 10px;
              }
            }
            span.outStock {
              padding: 3px 5px;
              border-radius: 5px;
              color: var(--error-alert);
              background-color: var(--error-alert-light);
              font-size: 0.8rem;
            }
            span.rate {
              i {
                color: var(--alert);
                padding: 0 5px;
              }
            }
            button {
              background-color: var(--main-color-light);
              padding: 5px;
              font-size: 20px;
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 5px;
              &:hover {
                background-color: var(--main-color);
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}
