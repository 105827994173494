@import '../../../styles/mixins.scss';

.chatBody {
  .chatBody__message {
    display: flex;
    align-items: center;
    @include xs {
      flex-direction: column;
      align-items: flex-start;
    }
    p {
      padding: 10px 20px;
      background-color: var(--secondary-background);
      margin-bottom: 20px;
      box-shadow: 0 1px 2px rgb(56 65 74 / 10%);
      border-radius: 5px;
      position: relative;
      max-width: 70%;
      &:before {
        content: "";
        position: absolute;
        top: 0px;
        left: -9px;
        width: 0;
        height: 0;
        border-top: 20px solid var(--secondary-background);
        border-right: 20px solid transparent;
        transform: rotate(90deg);
      }
      @include xs {
        font-size: 0.8rem;
        max-width: 80%;
        margin: 5px 0 !important;
      }
    }
    span {
      font-size: 0.8rem;
      margin: 0 10px 20px;
      opacity: 0.8;
      @include xs {
        font-size: 0.7rem;
        margin-bottom: 10px;
      }
    }

    img {
      max-width: 300px;
      max-height: 300px;
      object-fit: contain;
      border-radius: 5px;
      margin-bottom: 20px;
      cursor: pointer;
      &:hover {
        filter: brightness(0.8);
      }
    }

    &.me {
      flex-direction: row-reverse;
      p {
        background-color: var(--main-color);
        color: rgb(234, 233, 233);
        &:before {
          content: none;
        }
        &:after {
          content: "";
          position: absolute;
          top: 0px;
          right: -9px;
          width: 0;
          height: 0;
          border-top: 20px solid var(--main-color);
          border-right: 20px solid transparent;
          transform: rotate(0deg);
        }
      }
    }

    &.ar {
      p {
        &:before {
          left: unset;
          right: -9px;
          transform: rotate(0deg);
        }
      }

      &.me {
        p {
          &:after {
            right: unset;
            left: -9px;
            transform: rotate(90deg);
          }
        }
      }
    }
  }

  .chatBody__message_loader {
    width: 60%;
    display: flex;
    margin-bottom: 5px;
    &.me {
      float: right;
      &.ar {
        float: left;
      }
    }
  }
}