.orderSpecs {
  .head {
    padding: 10px 20px;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-weight: 500;
      i {
        font-size: 0.9rem;
      }
    }
    button {
      background-color: var(--main-color-light);
      color: var(--main-color);
      border: 1px solid var(--main-color);
      font-size: 0.8rem;
      padding: 3px 10px;
      border-radius: 5px;
      cursor: pointer;
      &:hover {
        background-color: var(--main-color);
        color: #fff;
      }
    }
  }
  .orderSpecs__logistics {
    border-radius: 5px;
    margin-bottom: 20px;
    .content {
      padding: 20px;
      img {
        width: 100px;
        margin: auto;
      }
      p, span {
        display: block;
        text-align: center !important;
        margin-bottom: 3px;
        font-size: 0.85rem;
      }
      p {
        font-weight: 500;
        font-size: 1rem;
      }
      span {
        opacity: 0.8;
      }
    }
  }

  .orderSpecs__customer {
    border-radius: 5px;
    margin-bottom: 20px;
    .content {
      padding: 10px 20px;
      .customer {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        img {
          width: 50px;
          height: 50px;
          border-radius: 5px;
        }
        div {
          padding: 0 10px;
          p {
            font-weight: 600;
          }
          span {
            font-size: 0.8rem;
            opacity: 0.8;
          }
        }
      }
      p {
        font-size: 0.9rem;
        span {
          padding: 0 7px;
        }
      }
    }
  }

  .orderSpecs__billing, .orderSpecs__payment, .orderSpecs__status {
    margin-bottom: 20px;
    border-radius: 5px;
    .content {
      padding: 10px 20px;
      li {
        font-size: 0.9rem;
        margin-bottom: 5px;
        font-weight: 450;
      }
    }
  }
}