@import '../../../styles/mixins';
@import '../../../styles/extends';

.returnsTab {
  padding: 0px 20px;
  border-radius: 5px;
  animation: show 0.3s ease-in-out;
  @keyframes show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @include sm {
    padding: 0 10px;
  }

  @include xs {
    padding: 0 0px;
  }

  .resetFilter {
    display: flex;
    justify-content: flex-end;
    span {
      cursor: pointer;
      color: var(--secondary-color);
    }
  }

  .returnsTab__filters {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 1rem;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--border-color);
    div {
      grid-column: span 2;
      @include sm {
        grid-column: span 2;
      }
      @include xs {
        grid-column: span 6;
      }
      label {
        font-size: 0.9rem;
        font-weight: 500;
      }
      input {
        @extend %input;
        padding: 10px 15px;
        width: 100%;
      }
      i {
        top: 30%;
      }
    }
  }

  .datePicker {
    div {
      width: 100%;
    }
    .datePicker__text {
      h6 {
        font-size: 17px;
      }
      p {
        font-size: 15px;
        opacity: 0.8;
      }
    }
    .datePicker__date {
      position: relative;
      display: flex;
      align-items: center;
      input {
        background-color: var(--default-background);
        color: var(--text-color);
        border-radius: 5px 0 0 5px;
        border: none;
        box-shadow: 0 0 2px rgb(0 0 0 / 5%);
        padding: 10px 15px;
        cursor: pointer;
        outline: none;
        font-size: 14px;
        min-height: 42px;
      }
  
      & > i {
        padding: 8px 15px;
        cursor: pointer;
        outline: none;
        font-size: 16px;
        background-color: var(--main-color);
        color: #fff;
        border-radius: 0 5px 5px 0;
        box-shadow: 0 0 2px rgb(0 0 0 / 5%);
        min-height: 42px;
      }
  
      button {
        border-radius: 5px;
        svg {
          color: #fff;
        }
      }
      fieldset {
        border-color: transparent !important;
        box-shadow: 0 1px 2px rgb(56 65 74 / 10%);
      }
      &.ar {
        input {
          border-radius: 0 5px 5px 0;
        }
        & > i {
          border-radius: 5px 0 0 5px;
        }
      }
    }
  }

  .pagination {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include xs {
      flex-direction: column;
    }
  }
}