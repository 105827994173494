@import '../../styles/mixins';

.settings {
  .settings__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    h6 {
      font-size: 16px;
      font-weight: 700;
    }
    p {
      span {
        font-weight: 400;
      }
    }
  }

  .settings__smallLink {
    display: flex;
    justify-content: flex-end;
    display: none;
    padding: 5px 20px;
    margin-bottom: 10px;
    border-radius: 5px;
    i {
      background-color: var(--default-background);
      width: 40px;
      height: 40px;
      display: grid;
      place-items: center;
      border-radius: 5px;
      font-size: 1.2rem;
      cursor: pointer;
    }
    @include md {
      display: block;
    }
  }

  .settings__settingsNav {
    @include md {
      display: none;
    }
  }
}