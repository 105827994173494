.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 99999999999;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /**
  * push_pop
  * lil push pop effect, push one side out and one side in
  * @author jh3y
*/
.push_pop {
  --duration: .85;
  height: 100px;
  width: 100px;
  position: relative;
  overflow: hidden; }
  .push_pop > div:nth-of-type(1) {
    height: 20px;
    width: 20px;
    position: absolute;
    -webkit-animation: push_pop-slide calc(var(--duration) * 1s) infinite alternate ease-in-out;
            animation: push_pop-slide calc(var(--duration) * 1s) infinite alternate ease-in-out;
    -webkit-transform: translate(0, -100%);
            transform: translate(0, -100%);
    top: 100%;
    left: 0; }
    .push_pop > div:nth-of-type(1):after {
      -webkit-animation: push_pop-flip calc(var(--duration) * 1s) infinite alternate ease-in-out;
              animation: push_pop-flip calc(var(--duration) * 1s) infinite alternate ease-in-out;
      background: var(--main-color);
      content: '';
      height: 100%;
      position: absolute;
      width: 100%; }
  .push_pop > div:nth-of-type(2) {
    background: var(--main-color);
    height: 30px;
    left: 50%;
    position: absolute;
    top: 100%;
    -webkit-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%);
    width: 20px; }
  .push_pop:after, .push_pop:before {
    -webkit-animation-name: push_pop-pushed;
            animation-name: push_pop-pushed;
    -webkit-animation-duration: calc(var(--duration) * 1s);
            animation-duration: calc(var(--duration) * 1s);
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease;
            animation-timing-function: ease;
            background: var(--main-color);
            // background: linear-gradient(0deg, #fff 0%, rgba(64,115,158,1) 100%);
    bottom: 0;
    content: '';
    height: 40px;
    position: absolute;
    width: 20px; }
  .push_pop:before {
    animation-direction: alternate-reverse;
    left: 0; }
  .push_pop:after {
    -webkit-animation-direction: alternate;
            animation-direction: alternate;
    right: 0; }

@-webkit-keyframes push_pop-pushed {
  0%, 72.5% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  100% {
    -webkit-transform: translate(0, 100%);
            transform: translate(0, 100%); } }

@keyframes push_pop-pushed {
  0%, 72.5% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  100% {
    -webkit-transform: translate(0, 100%);
            transform: translate(0, 100%); } }

@-webkit-keyframes push_pop-flip {
  0% {
    -webkit-transform: translate(0, 0) rotate(0deg);
            transform: translate(0, 0) rotate(0deg); }
  50% {
    -webkit-transform: translate(0, -80px) rotate(90deg);
            transform: translate(0, -80px) rotate(90deg); }
  100% {
    -webkit-transform: translate(0, 0) rotate(180deg);
            transform: translate(0, 0) rotate(180deg); } }

@keyframes push_pop-flip {
  0% {
    -webkit-transform: translate(0, 0) rotate(0deg);
            transform: translate(0, 0) rotate(0deg); }
  50% {
    -webkit-transform: translate(0, -80px) rotate(90deg);
            transform: translate(0, -80px) rotate(90deg); }
  100% {
    -webkit-transform: translate(0, 0) rotate(180deg);
            transform: translate(0, 0) rotate(180deg); } }

@-webkit-keyframes push_pop-slide {
  to {
    -webkit-transform: translate(0, -100%) translate(80px, 0);
            transform: translate(0, -100%) translate(80px, 0); } }

@keyframes push_pop-slide {
  to {
    -webkit-transform: translate(0, -100%) translate(80px, 0);
            transform: translate(0, -100%) translate(80px, 0); } }

.line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
  .circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--secondary-color);
    opacity: 0.7;
    // background: linear-gradient(90deg, #fff 0%, var(--main-color) 100%);
    margin-top: 10px;
  }
}
















    /**
    * scaling_circle
    *
    * @author jh3y
  */
//   @-webkit-keyframes scaling_circle {
//     0%, 30%, 50%, 100% {
//       -webkit-transform: rotate(calc(var(--angle) * 1deg)) translate(0, calc(var(--radius) * 1px)) scale(1);
//               transform: rotate(calc(var(--angle) * 1deg)) translate(0, calc(var(--radius) * 1px)) scale(1); }
//     40% {
//       -webkit-transform: rotate(calc(var(--angle) * 1deg)) translate(0, calc(var(--radius) * 1px)) scale(0);
//               transform: rotate(calc(var(--angle) * 1deg)) translate(0, calc(var(--radius) * 1px)) scale(0); } }
//   @keyframes scaling_circle {
//     0%, 30%, 50%, 100% {
//       -webkit-transform: rotate(calc(var(--angle) * 1deg)) translate(0, calc(var(--radius) * 1px)) scale(1);
//               transform: rotate(calc(var(--angle) * 1deg)) translate(0, calc(var(--radius) * 1px)) scale(1); }
//     40% {
//       -webkit-transform: rotate(calc(var(--angle) * 1deg)) translate(0, calc(var(--radius) * 1px)) scale(0);
//               transform: rotate(calc(var(--angle) * 1deg)) translate(0, calc(var(--radius) * 1px)) scale(0); } }

//   .scaling_circle {
//     --radius: 25;
//     --size: 10;
//     height: calc(var(--size) * 1px);
//     width: calc(var(--size) * 1px);
//     position: absolute; 
//   }
//   .scaling_circle div {
//     height: 100%;
//     width: 100%;
//     position: absolute;
//     border-radius: 100%;
//     background-color: var(--main-color);
//     -webkit-animation: scaling_circle 0.8s calc(var(--delay) * 1s) infinite ease;
//             animation: scaling_circle 0.8s calc(var(--delay) * 1s) infinite ease;
//     -webkit-transform: rotate(calc(var(--angle) * 1deg)) translate(0, calc(var(--radius) * 1px));
//             transform: rotate(calc(var(--angle) * 1deg)) translate(0, calc(var(--radius) * 1px)); }
//     .scaling_circle div:nth-child(1) {
//       --angle: 45;
//       --delay: 0.1; }
//     .scaling_circle div:nth-child(2) {
//       --angle: 90;
//       --delay: 0.2; }
//     .scaling_circle div:nth-child(3) {
//       --angle: 135;
//       --delay: 0.3; }
//     .scaling_circle div:nth-child(4) {
//       --angle: 180;
//       --delay: 0.4; }
//     .scaling_circle div:nth-child(5) {
//       --angle: 225;
//       --delay: 0.5; }
//     .scaling_circle div:nth-child(6) {
//       --angle: 270;
//       --delay: 0.6; }
//     .scaling_circle div:nth-child(7) {
//       --angle: 315;
//       --delay: 0.7; }
//     .scaling_circle div:nth-child(8) {
//       --angle: 360;
//       --delay: 0.8; }
}