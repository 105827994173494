@import '../../../styles/mixins';
@import '../../../styles/extends';

.createProduct {

  .createProducts__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    h6 {
      font-size: 16px;
      font-weight: 700;
    }
    p {
      span {
        font-weight: 400;
      }
    }
    @include md {
      margin: 0px auto 10px;
    }
  }

  .createProduct__wrapper {
    @include md {
      flex-direction: column-reverse;
    }
  }

  .submit {
    text-align: center !important;
    button {
      @extend %btn;
      padding: 10px 30px;
      border-radius: 4px;
    }
  }

}