@import '../../../styles/mixins';

.productDetails {
  .productDetails__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    h6 {
      font-size: 16px;
      font-weight: 700;
    }
    p {
      span {
        font-weight: 400;
      }
    }
  }

  .wrapper {
    border-radius: 5px;
    padding-bottom: 30px;
    position: relative;
  }

  .productDetails__imageArea {
    padding: 20px;
    position: -webkit-sticky;
    position: sticky;
    top: 70px;
    @include lg {
      width: 500px;
      margin: auto;
    }
    @include xs {
      width: 100%;
    }
    .bigImage {
      img {
        width: 100%;
        height: 400px;
        object-fit: contain;
        margin-bottom: 10px;
        background-color: var(--default-background);
        padding: 10px;
        border-radius: 10px;
      }
    }
    .thumbnails {
      direction: ltr !important;
      display: flex;
      justify-content: center;
      ul {
        li {
          border: 1px solid var(--border-color);
          border-radius: 5px;
          margin: 5px;
          padding: 5px;
          cursor: pointer;
          img {
            object-fit: contain;
            max-height: 60px;
          }
        }
      }
    }
  }

  .productDetails__details {
    padding: 20px;
    .name {
      display: flex;
      justify-content: space-between;
      h5 {
        font-size: 1.3rem;
      }
      i {
        width: 40px;
        height: 40px;
        border-radius: 5px;
        background-color: var(--default-background);
        cursor: pointer;
        display: grid;
        place-items: center;
        font-size: 1rem;
        &:hover {
          background-color: var(--main-color);
          color: #fff;
        }
      }
    }
    h6 {
      font-size: 1rem;
    }
    p {
      font-size: 0.85rem;
      font-weight: 400;
      opacity: 0.8;
    }
    .productDetails__details_rate {
      display: flex;
      align-items: center;
      margin-top: 10px;
      span {
        span {
          width: 20px;
        }
      }
      p {
        margin: 0 20px;
        display: inline-block;
      }
    }

    .productDetails__details_features {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      gap: 10px;
      margin-top: 20px;
      .feature {
        display: flex;
        align-items: center;
        grid-column: span 3;
        border: 2px dashed var(--border-color);
        padding: 15px 10px;
        border-radius: 5px;
        @include xl {
          grid-column: span 6;
        }
        @include xs {
          grid-column: span 12;
        }
        &.ar {
          .one, .two {
            margin: 0 0px 0 10px;
          }
        }
        .one, .two {
          margin: 0 10px 0 0px;
          width: 35px;
          height: 35px;
          display: grid;
          place-items: center;
          border-radius: 5px;
          &.one {
            background-color: rgba(64, 115, 158, 0.3);
            color: var(--main-color);
          }
          &.two {
            background-color: rgba(255, 115, 0, 0.3);
            color: var(--secondary-color);
          }
        }
        p {
          font-size: 0.85rem;
        }
        .number {
          font-size: 1.2rem;
          font-weight: 450;
        }
      }
    }

    .productDetails__details_variants {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin-top: 20px;
      .variants {
        grid-column: span 1;
        @include sm {
          grid-column: span 2;
        }
        .variant {
          width: auto;
          min-width: 50px;
          height: 35px;
          padding: 2px 15px;
          display: inline-block;
          border: 1px solid var(--border-color);
          text-align: center !important;
          line-height: 30px;
          font-size: 0.9rem;
          margin: 0 5px;
          border-radius: 5px;
          cursor: pointer;
          &.disabled {
            opacity: 0.5;
            cursor: auto;
            &:hover {
              background-color: transparent;
              color: var(--text-color);
            }
          }
          &:hover {
            background-color: var(--main-color);
            color: #fff;
          }
        }
      }
      .colors {
        grid-column: span 1;
        @include sm {
          grid-column: span 2;
          margin-top: 1rem;
        }
        .colorsWrapper {
          display: flex;
          .wrapper {
            border: 1px solid var(--border-color);
            margin: 0 3px;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            padding: 0;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            cursor: pointer;
            .color {
              width: 22px;
              height: 22px;
              border-radius: 50%;
              &.disabled {
                opacity: 0.5;
                cursor: auto;
              }
            }
          }
        }
      }
    }

    .productDetails__details_description {
      margin-top: 20px;
    }

    .productDetails__details_specifications {
      margin-top: 10px;
      .links {
        button {
          background-color: transparent;
          border: none;
          padding: 6px 10px;
          cursor: pointer;
          outline: none;
          &.selected {
            font-weight: 450;
            color: var(--main-color);
            border-bottom: 2px solid var(--main-color);
          }
        }
      }

      .specsBox, .detailBox {
        border: 1px solid var(--border-color);
        padding: 10px 20px;
      }

      .specsBox {
        table {
          width: 100%;
          tbody {
            tr {
              td {
                table {
                  tr {
                    td {
                      font-size: 0.9rem;
                      border-bottom: 1px solid var(--border-color);
                      padding: 5px 0;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .detailBox {
        ul {
          padding: 0 20px;
          list-style: circle;
          li {
            font-size: 0.95rem;
          }
        }
      }

    }

  }
}