@import '../../../../styles/mixins.scss';
@import '../../../../styles/extends.scss';

.body__order {
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: repeat(12, 1fr);
  margin-bottom: 5px;
  padding: 20px;
  &.choosed {
    background-color: var(--default-background);
  }
  @include md {
    display: block;
  }

  .body__order_img {
    display: flex;
    align-items: flex-start;
    grid-column: span 12;
    img {
      width: 70px;
      height: 70px;
      margin-right: 20px;
      object-fit: contain;
      &.ar {
        margin-right: unset;
        margin-left: 20px;
      }
      @include lg {
        margin-right: 20px;
      }
      @include md {
        margin-right: 20px;
        width: 130px;
      }
    }
    h6 {
      margin: 0 !important;
      font-size: 0.9rem !important;
      @include xs {
        font-size: 0.8rem;
      }
    }

    .color {
      display: flex;
      align-items: center;
      gap: 10px;
      p {
        width: 40px;
        height: 20px;
        border-radius: 5px;
      }
    }

    .inputs {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      label {
        font-size: 0.8rem;
        display: block;
      }
      input {
        background-color: var(--default-background);
        border: 1px solid var(--border-color);
        padding: 5px 10px;
        width: 80px;
        border-radius: 5px;
        font-size: 0.8rem;
        text-align: center !important;
      }
    }

    button {
      @extend %btn;
      font-size: 0.8rem;
      &:hover {
        background-color: transparent;
        border-radius: 5px;
        color: var(--main-color);
        border-color: var(--main-color);
      }
    }
  }

  .body__order_actions {
    display: flex;
    flex-direction: column;
    grid-column: 10 / span 6;
    @include xl {
      grid-column: 9 / span 6;
    }
    @include sm {
      margin-top: 10px;
    }
    button {
      margin: 3px 0px;
      @extend %mainBtn;
      font-size: 13px;
      max-width: 300px;
      border-radius: 8px;
      &.review {
        background-color: transparent;
        color: var(--main-color);
      }
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        &:hover {
          background-color: var(--main-color);
          color: #fff;
        }
      }
    }
  }

}