@import '../../styles/extends';

.returns {
  .returns__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    h6 {
      font-size: 1rem;
      font-weight: 700;
    }
    p {
      span {
        font-weight: 400;
      }
    }
  }

  .returns__table {
    border-radius: 5px;
    .returns__table_head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px dashed var(--border-color);
      padding: 15px 20px;
      h6 {
        font-size: 1rem;
      }
      button {
        @extend %btn;
        padding: 8px 20px;
      }
    }

    .returns__table_options {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      gap: 1rem;
      align-items: center;
      padding: 20px;
      .search {
        position: relative;
        i {
          position: absolute;
          top: 22%;
          left: 15px;
          opacity: 0.6;
        }
        input {
          padding-left: 40px;
        }
        &.ar {
          i {
            left: unset;
            right: 15px;
          }
          input {
            padding-left: unset;
            padding-right: 40px;
          }
        }
      }
      div {
        grid-column: span 3;
        @include sm {
          grid-column: span 6;
        }
        @include xs {
          grid-column: span 12;
        }
      }
      input {
        @extend %input;
        height: 42px;
        width: 100%;
      }

      .date {
        & > div {
          border: 1px solid var(--border-color);
          padding-right: 10px;
          background-color: var(--default-background);
          border-radius: 5px;
          height: 42px;
          input {
            border: none;
            padding: 0 20px;
            height: 40px;
          }
        }
        svg {
          color: var((--text-color));
        }
        fieldset {
          border: none;
        }
        &.ar {
          padding-right: 0;
          padding-left: 10px;
          div {
            padding-right: 0;
          }
        }
      }
    }

    .returns__table_filters {
      padding: 0 20px;
      display: flex;
      p {
        padding: 5px 12px;
        font-size: 0.95rem;
        cursor: pointer;
        span {
          margin: 0 3px;
        }
        &.selected {
          color: var(--main-color);
          border-bottom: 2px solid var(--main-color);
          font-weight: 600;
        }
      }
    }
  }
}