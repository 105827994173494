@import '../../../styles/mixins.scss';

.notification {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  div {
    padding: 0 !important;
  }
  &.unRead {
    background-color: var(--main-color-very-light);
    border-left: 4px solid var(--main-color);
    &.ar {
      border-left: unset;
      border-right: 4px solid var(--main-color);
    }
    &:hover {
      background-color: var(--main-color-light) !important;
      div {
        background-color: var(--main-color-light) !important;
      }
    }
  }

  .notification__wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 90%;
    
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      @include xs {
        width: 30px;
        height: 30px;
      }
    }

    h6 {
      margin: 0px;
      @include xs {
        font-size: 0.8rem;
      }
    }

    p {
      font-size: 0.9rem;
      margin: 0px;
      @include xs {
        font-size: 0.8rem;
      }
    }
  }

  i {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    color: var(--error-alert);
    background-color: var(--error-alert-light);
    margin: 0 !important;
    &:hover {
      background-color: var(--error-alert);
      color: #fff;
    }
    @include xs {
      width: 30px;
      height: 30px;
      font-size: 0.8rem;
    }
  }
}