@import '../../../styles/mixins';
@import '../../../styles/extends';

.createProductDetails {
  .section {
    padding: 10px 15px;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgb(56 65 74 / 10%);
    h6 {
      font-size: 0.95rem;
    }
  }
  .createProductDetails__text {
    h6 {
      margin-top: 10px
    }
    input, textarea {
      @extend %input;
      width: 100%;
      // margin-bottom: 10px;
    }
    textarea {
      height: 150px;
      &.error {
        border-color: var(--error-alert);
      }
    }
    p {
      color: var(--error-alert);
      font-size: 0.9rem;
      display: block;
      margin: 0;
    }
  }

  .createProductDetails__gallery {
    h5 {
      font-size: 1.2rem;
      padding-bottom: 10px;
      margin-bottom: 15px;
      border-bottom: 1px solid var(--border-color);
    }
    h6 {
      font-size: 1rem;
      &.separate {
        margin-top: 20px;
      }
    }
    p {
      font-size: 0.8rem;
      font-weight: 450;
      opacity: 0.8;
    }
    .createProductDetails__gallery_field {
      display: flex;
      align-items: center;
      margin-top: 10px;
      margin-bottom: 15px;
      button {
        width: 150px;
        background-color: var(--default-background);
        border: 1px solid var(--border-color);
        padding: 7px 10px;
        text-align: center !important;
        font-size: 0.9rem;
        border-radius: 5px;
        cursor: pointer;
        outline: none;
        &:hover {
          background-color: var(--hover-color)
        }
      }
      input, textarea {
        @extend %input;
        width: 100%;
      }
    }

    .createProductDetails__gallery_Box {
      height: 250px;
      border: 2px dashed var(--border-color);
      border-radius: 5px;
      margin-top: 10px;
      cursor: pointer;
      &.error {
        border-color: var(--error-alert);
      }
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        i {
          font-size: 2.5rem;
          text-align: center !important;
        }
        p {
          font-weight: 400;
          font-size: 1rem;
        }
        @include xs {
          i {
            font-size: 2rem;
          }
          p {
            font-size: 0.9rem;
          }
        }
      }
      
      .images {
        .wrapper {
          display: flex;
          flex-direction: row;
          height: auto;
        }
        .image {
          width: 70px;
          height: 70px;
          border-radius: 5px;
          margin: 0 10px 10px;
          position: relative;
          img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
            object-fit: contain;
          }
          i {
            position: absolute;
            top: -20%;
            right: -20%;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: var(--default-background);
            font-size: 0.9rem;
            display: grid;
            place-items: center;
            &:hover {
              background-color: var(--main-color);
              color: #fff;
            }
          }
        }
      }
      &.entered {
        i, p {
          opacity: 0.5;
        }
      }
    }

    span {
      color: var(--error-alert);
      font-size: 0.9rem;
      display: block;
      margin-top: 5px;
    }
    .btn {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
      button {
        @extend %btn;
      }
    }

  }

  .createProductDetails__general, .createProductDetails__specs {
    margin-bottom: 20px;
    border-radius: 5px;
    padding: 10px 15px;
    box-shadow: 0 1px 2px rgb(56 65 74 / 10%);
    h6 {
      font-size: 1rem;
      margin-bottom: 10px;
    }

    .fields {
      display: flex;
      gap: 5px;
      div {
        input {
          width: 100%;
        }
      }
      @include xs {
        display: block;
        input {
          margin-bottom: 1px;
        }
      }
      input {
        @extend %input;
        width: 50%;
      }
    }

    p {
      color: var(--error-alert);
      font-size: 0.9rem;
      display: block;
      margin-top: 5px;
    }

    .btn {
      display: flex;
      justify-content: flex-end;
      button {
        @extend %btn;
        margin-top: 10px;
      }
    }

    .list {
      border: 2px dashed var(--border-color);
      padding: 10px;
      border-radius: 5px;
      margin-top: 10px;
      ul {
        li {
          display: grid;
          grid-template-columns: repeat(12, 1fr);
          gap: 0.1rem;
          align-items: center;
          justify-content: center;
          margin-bottom: 5px;
          text-transform: capitalize;
          font-size: 0.9rem;
          margin-bottom: 25px;
          &:last-of-type {
            margin-bottom: 0;
          }
          span {
            display: flex;
            align-items: center;
            grid-column: span 3;
            background-color: var(--default-background);
            padding: 5px 12px;
            border-radius: 5px;
            margin-bottom: 4px;
            @include xl {
              grid-column: span 6;
            }
            @include lg {
              grid-column: span 6;
            }
            @include xs {
              grid-column: span 12;
            }
            &:last-of-type {
              background-color: transparent !important;
              padding: 0 2px;
            }
            
            i.cancel {
              background-color: var(--default-background);
              width: 25px;
              height: 25px;
              border-radius: 50%;
              font-size: 0.8rem;
              display: grid;
              place-items: center;
              margin: 0 5px;
              cursor: pointer;
              &:hover {
                background-color: var(--main-color);
                color: #fff;
              }
            }
          }
          button {
            @extend %btn;
            padding: 5px 10px;
            font-size: 0.75rem;
          }
        }
      }
    }
  }

  .createProductDetails__specs {
    .fields {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      div {
        input {
          width: 100%;
        }
      }
    }
    .list {
      ul {
        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 5px;
          &:last-of-type {
            margin-bottom: 0;
          }
          i {
            background-color: var(--default-background);
            width: 25px;
            height: 25px;
            border-radius: 50%;
            font-size: 0.8rem;
            display: grid;
            place-items: center;
            margin: 0 5px;
            cursor: pointer;
            &:hover {
              background-color: var(--main-color);
              color: #fff;
            }
          }
        }
      }
    }
  }

  .createProductDetails__price {
    padding-bottom: 30px;
    box-shadow: 0 1px 2px rgb(56 65 74 / 10%);
    .createProductDetails__price_fields {
      .price {
        display: flex;
        align-items: center;
        justify-content: space-between;
        div:not(.switch) {
          width: calc(100% - 150px);
          input {
            // width: calc(100% - 150px);
            width: 100%;
          }
        }
        .switch {
          display: flex;
          align-items: center;
          label {
            margin: 0 1px;
          }
          i {
            margin: 0 3px;
          }
        }
      }
      .discount {
        margin-top: 20px;
      }
      label {
        font-size: 0.9rem;
        display: block;
        padding: 0 2px;
        @include xs {
          margin: 0
        }
      }
      input {
        @extend %input;
        width: 90%;
      }
      .choose {
        display: flex;
        button {
          background-color: var(--default-background);
          padding: 5px 15px;
          width: auto;
          min-width: 120px;
          height: 37px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          border: 1px solid var(--border-color);
          font-size: 0.9rem;
          span {
            transform: translateY(1px);
          }
          &:hover {
            background-color: var(--main-color);
            color: #fff;
          }
        }
        .chooseList {
          right: -200px !important;
          display: none !important;
        }

        div:last-of-type {
          width: 100%;
          input {
            width: 100%;
          }
        }
      }
    
      .date {
        label {
          margin-top: 20px;
        }
        & > div {
          border: 1px solid var(--border-color);
          padding-right: 10px;
          background-color: var(--default-background);
          border-radius: 5px;
          height: 42px;
          width: 50%;
          @include sm {
            width: 100%;
          }
          input {
            border: none;
            padding: 0 15px;
            height: 40px;
          }
        }
        svg {
          color: var((--text-color));
        }
        fieldset {
          border: none;
        }
        &.ar {
          padding-right: 0;
          padding-left: 10px;
          div {
            padding-right: 0;
          }
        }
      }
    }
  }

}