.chat {
  display: flex;
  flex-direction: column;
  height: 80vh;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  box-shadow: 0 1px 2px rgb(56 65 74 / 10%);

  .chat__chatHeader {
    padding: 10px 20px;
  }

  .chat__chatBody {
    flex: 1;
    overflow-y: scroll;
    padding: 20px;
  }

  .chat__chatBottom {
    // padding: 15px 20px;
  }
}