@import '../../styles/extends';

.loadingPart {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  @include lg {
    flex-direction: column;
  }
}

.addCountry {

  .addCountry__country {
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 5px;
    h6 {
      font-size: 1.2rem;
      margin-bottom: 10px;
    }
    input {
      @extend %input;
      width: 100%;
    }
  }

  h5 {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
  p {
    margin-bottom: 10px;
    font-size: 0.9rem;
    font-weight: 400;
    opacity: 0.8
  }

  .addCountry__create {
    padding: 20px;
    border-radius: 5px;

    label {
      font-size: 0.9rem;
    }
    
    input {
      @extend %input;
      display: block;
      width: 100%;
      margin-bottom: 5px;
    }

  }

  .btn {
    button {
      @extend %btn;
      margin-top: 10px;
      padding: 8px 20px;
      border-radius: 3px;
      width: 100%;
      text-align: center !important;
    }
  }

  .addNew {
    color: var(--main-color);
    font-weight: 550;
    cursor: pointer;
  }

  .deleteSelected {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
    font-size: 0.95rem;
    span {
      margin: 0 10px;
      color: var(--secondary-color);
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .addCountry__edit {
    overflow-x: scroll;
    padding: 10px 20px;
    border-radius: 5px;
    h6 {
      margin-bottom: 10px;
      font-size: 1.1rem;
    }

    table {
      min-width: 550px;
      thead {
        background-color: var(--default-background);
        tr {
          border: none;
          th {
            border: none;
            padding: 15px 10px 15px;
            color: var(--text-color);
            font-weight: 450;
            opacity: 0.8;
            font-size: 0.95rem;
            cursor: pointer;
            span {
              display: flex;
              justify-content: space-between;
            }
          }
        }
      }
      tbody {
        tr {
          border: none;
          cursor: pointer;
          &:hover {
            background-color: var(--hover-color);
          }
          td {
            border: none;
            padding: 8px 10px 8px;
            color: var(--text-color);
            font-weight: 450;
            font-size: 0.9rem;
            padding-top: 10px;
            padding-bottom: 10px;
            .category {
              display: flex;
              align-items: center;
              img {
                width: 40px;
                height: 40px;
              }
              span {
                margin: 0 10px;
              }
            }
            span.outStock {
              padding: 3px 5px;
              border-radius: 5px;
              color: var(--error-alert);
              background-color: var(--error-alert-light);
              font-size: 0.8rem;
            }
            span.rate {
              i {
                color: var(--alert);
                padding: 0 5px;
              }
            }
            button {
              background-color: var(--main-color-light);
              padding: 5px;
              font-size: 20px;
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 5px;
              &:hover {
                background-color: var(--main-color);
                color: #fff;
              }
            }
          }
        }
      }
    }

  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px 0px;
    border-top: 1px solid var(--border-color);
    p {
      color: var(--text-color);
      font-weight: 450;
      font-size: 0.95rem;
      margin: 0;
    }
  }


  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    button {
      @extend %btn;
      padding: 7px 20px;
      border-radius: 3px;
      margin-top: 10px;
    }
    .removeLogistic {
      button {
        background-color: var(--error-alert-light);
        color: var(--error-alert);
        border: 1px solid var(--error-alert);
        margin: 20px 10px 0;
        &:hover {
          background-color: var(--error-alert);
          color: #fff;
        }
      }
    }
  }

  .addCountry {
    display: flex;
    justify-content: flex-end;
    button {
      @extend %btn;
      padding: 7px 20px;
      border-radius: 3px;
      margin-top: 20px;
    }
  }

}