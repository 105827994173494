@import '../../../styles/mixins';
@import '../../../styles/extends';

.accordion_link {
  transition: all 0.3s ease-in-out;
  position: relative;
  margin-bottom: 15px;

  .link {
    padding: 3px 0;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    position: relative;
    border-left: 5px solid var(--main-color);
    background-color: var(--default-background);
    padding: 10px 0;
    box-shadow: 0 1px 2px rgb(56 65 74 / 10%);
    @include xs {
      flex-direction: column;
    }
    &.ar {
      border-left: unset;
      border-right: 5px solid var(--main-color);
    }

    .icon {
      pointer-events: none;
      display: flex;
      align-items: center;
      @include xs {
        margin-top: 5px;
      }

      span {
        padding: 3px 10px;
        border-radius: 5px;
        color: var(--main-color);
        font-size: 0.8rem;
        font-weight: 500;
        background-color: var(--main-color-light);
        margin: 0 3px;
        &.Opened {
          color: var(--success);
          background-color: var(--success-light);
        }
        &.Closed {
          color: var(--error-alert);
          background-color: var(--error-alert-light);
        }
        &.Unhandled {
          color: var(--alert);
          background-color: var(--alert-light);
        }
        @include xs {
          font-size: 0.7rem;
        }
      }
      
      i {
        font-size: 1rem;
        display: flex;
        align-items: center;
        padding: 0 20px;
        transition: all 0.2s ease-in-out;
        @include xs {
          font-size: 0.8rem;
        }
      }

    }

    .label {
      pointer-events: none;
      transition: opacity 0.1s ease-in-out;
      font-size: 1rem;
      font-weight: 600;
      margin: 0 15px;
      display: flex;
      align-items: center;
      @include xs {
        font-size: 0.9rem;
      }
      img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
      }
      span {
        margin: 0 10px;
      }
      i {
        font-size: 1.2rem;
        margin-right: 10px;
        @include xs {
          font-size: 1rem;
        }
      }
      &.ar {
        i {
          margin-right: unset;
          margin-left: 10px;
        }
      }
    }

    & > i {
      position: absolute;
      top: 25%;
      right: 15px;
      transition: all 0.2s ease-in-out;
      pointer-events: none;
      font-size: 1rem;
      &.ar {
        right: unset;
        left: 25px;
      }
    }
  }

  .subMenu {
    cursor: pointer;
    height: auto;
    overflow: hidden;
    max-height: 0;
    transition: all 0.3s ease-in-out;
    position: relative;
    z-index: 1;
    box-shadow: 0 1px 2px rgb(56 65 74 / 10%);

    .dropLayer {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: transparent;
      z-index: -1;
    }

    .accordionBody {
      padding: 20px 20px;
      background-color: var(--special-background);

      .description {
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          i {
            margin-right: 5px;
          }
        }
        div {
          button {
            @extend %btn;
            margin: 0 3px;
            padding: 5px 20px;
            i {
              font-size: 0.8rem;
              margin: 0 2px;
            }
          }
        }
      }

      .return {
        margin: 20px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .attachments {
        margin-bottom: 10px;
        h6 {
          font-size: 1.1rem;
          margin-bottom: 10px;
        }

        span {
          border: 1px dashed var(--main-color);
          padding: 3px 15px;
          font-size: 0.9rem;
          border-radius: 5px;
          margin: 0 3px;
          color: var(--main-color);
        }
      }

      .time {
        margin-top: 10px;
        color: var(--main-color);
        font-weight: 500;
        font-size: 0.9rem;
        i {
          margin-right: 5px;
        }
      }

      .actions {
        margin-top: 20px;
        button {
          @extend %btn;
          margin: 0 3px;
          &.deleteTicket {
            background-color: var(--error-alert-light);
            color: var(--error-alert);
            border-color: var(--error-alert);
            &:hover {
              background-color: var(--error-alert);
              color: #fff;
            }
          }
        }
      }
    }
    &.ar {
      .description {
        p {
          i {
            margin-right: unset;
            margin-left: 5px;
          }
        }
      }
      .time {
        i {
          margin-left: 5px;
        }
      }
    }
  }

  &.showSub {
    .link {
      .icon i {
        transform: rotate(180deg);
      }
    }
  }

  &.smallAccordion {
    .link {
      .label {
        font-size: 0.9rem;
      }
    }
    .subMenu {
      li {
        font-size: 0.85rem;
        padding-top: 5px;
      }
    }
  }

}