@import '../../../styles/mixins';
@import '../../../styles/extends';

.wrapper {
  animation: show 0.5s forwards 1;
  @keyframes show {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  .securitySettings {
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgb(56 65 74 / 10%);
  
    h6 {
      font-size: 1.2rem;
      margin-bottom: 15px;
      @include xs {
        font-size: 1.1rem;
      }
    }
    .securitySettings__fields {
      .securitySettings__fields_field {
        margin-bottom: 10px;
        label {
          display: block;
          font-size: 0.9rem;
          margin-top: 5px
        }
        input {
          @extend %input;
          width: 100%;
          padding: 10px 20px;
        }
      }
    }
  }
  .btn {
    display: grid;
    justify-content: flex-end;
    button {
      @extend %btn;
      margin-top: 10px;
      padding: 8px 30px;
    }
  }
}