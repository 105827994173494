@import "../../styles/mixins";
@import "../../styles/extends";

.auth {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--main-color);
  color: rgb(71, 71, 71);
  background-image: var(--svg-bg);
  background-attachment: fixed;
  background-size: cover;

  .auth__authBox {
    width: 1000px;
    min-height: 600px;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    padding: 35px 25px;
    box-shadow: 2px 2px 10px rgba(0 0 0 / 0.1);

    @include lg {
      width: 900px;
    }
    @include md {
      width: 700px;
    }
    @include sm {
      width: 500px;
    }
    @include xs {
      width: 95%;
      margin: auto;
      padding: 0;
    }

    .auth__authBox_fields {
      min-height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .fieldsWrapper {
        width: 80%;
        img {
          width: 130px;
          margin: 0 auto 30px;
          @include xs {
            width: 100px;
          }
        }
        h4 {
          font-size: 30px;
          color: var(--main-color);
          @include xs {
            font-size: 25px;
          }
        }
        & > p {
          margin: 5px 0 10px;
          font-size: 15px;
          color: var(--main-color);
          @include xs {
            font-size: 15px;
          }
        }

        label {
          font-size: 14px;
          color: var(--main-color);
          position: relative;
          top: 10px;
          left: 5px;
        }

        input {
          @extend %form-element;
          border-color: var(--main-color);
          color: var(--main-color);
          font-weight: 200;
        }

        .actions {
          margin-bottom: 30px;
          p {
            font-size: 15px;
            margin: 10px 0 5px;
            @include lg {
              font-size: 14px;
            }
            span {
              color: var(--main-color);
              cursor: pointer;
              &:hover {
                text-decoration: underline;
              }
            }
          }
          button {
            @extend %formBtn;
            transition: all 0.3s ease;
          }
        }

        .externalAuth {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid var(--main-color);
          padding: 10px;
          margin-bottom: 10px;
          border-radius: 5px;
          cursor: pointer;
          color: var(--main-color);
          font-weight: 200;
          transition: all 0.3s ease;

          &:hover {
            background-color: var(--main-color);
            color: #fff;
          }

          img {
            width: 25px;
            margin: 0 10px;
          }
          p {
            margin: 0;
            font-size: 15px;
          }
          @include xs {
            img {
              width: 20px;
            }
            p {
              font-size: 15px;
            }
          }
        }
      }
    }

    .auth__authBox_info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      @include md {
        display: none;
      }

      .logo {
        display: flex;
        align-items: center;
        img {
          width: 50px;
          height: 50px;
          margin: 0 10px;
        }
        h4 {
          color: var(--main-color);
        }
      }

      .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        h5 {
          color: var(--main-color);
        }
        p {
          text-align: center !important;
          margin-top: 10px;
          margin-bottom: 10px;
          width: 85%;
          color: var(--main-color);
          font-size: 15px;
        }
        button {
          @extend %formBtn;
          width: 200px;
          margin-top: 10px;
          border: 1px solid var(--main-color);
          background-color: transparent;
          color: var(--main-color);
          padding: 8px 0 10px;
          border-radius: 15px;
          font-size: 14px;
          transition: all 0.3s ease;

          &:hover {
            background-color: var(--main-color);
            color: #fff;
          }
        }
      }

      .footer {
        color: var(--main-color);
        font-weight: 200;
      }
    }
  }
}
