@import '../../../styles/mixins';

.field {
  .imageArea {
    height: 250px;
    border: 2px dashed var(--border-color);
    border-radius: 5px;
    margin-top: 10px;
    cursor: pointer;
    &.error {
      border: 2px dashed var(--error-alert);
    }
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      i {
        font-size: 2.2rem;
        opacity: 0.7;
        text-align: center !important;
        @include sm {
          font-size: 2rem;
        }
      }
      p {
        font-weight: 400;
        opacity: 0.8;
        margin: 0;
        font-size: 0.9rem;
      }
    }
    .images {
      .image {
        width: 130px;
        height: 130px;
        border-radius: 5px;
        margin-bottom: 10px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
          object-fit: contain;
        }
        i {
          position: absolute;
          top: -20%;
          right: -20%;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-color: var(--hover-color);
          font-size: 0.9rem;
          display: grid;
          place-items: center;
          &:hover {
            background-color: var(--main-color);
            color: #fff;
          }
        }
        @include xs {
          width: 80px;
          height: 80px;
        }
      }
    }
  }
  .error {
    font-size: 0.85rem;
    color: var(--error-alert);
  }
}