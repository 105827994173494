@import '../../styles/mixins.scss';

.footer {
  margin-top: 50px;
  padding: 10px 40px;
  border-top: 1px dashed var(--border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  p {
    font-weight: 450;
  }
  @include sm {
    p {
      font-size: 0.9rem;
    }
  }
  @include xs {
    display: block;
    p {
      text-align: center !important;
      font-size: 0.8rem;
    }
  }
}