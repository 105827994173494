@import "./mixins.scss";
@import "./variables.scss";

// @font-face {
//   font-family: IBMPlexSansArabic;
//   src: url("../../public/fonts/IBMPlexSansArabic-Regular.ttf");
//   font-display: swap;
// }

/***************  { General Styles For Elements }   *****************/
$mainColor: #40739e;
$h1: 2rem;
$h2: 1.714rem;
$h3: 1.5rem;
$h4: 1.286rem;
$h5: 1.07rem;
$h6: 1rem;
$p: 1rem;

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

// *:not(img):not(i):not(.MuiTooltip-popper):not(.MuiTooltip-popper *) {
//   transition: all 0.2s ease-in-out;
// }

html,
body {
  height: 100%;
  // font-family: IBMPlexSansArabic;
  font-family: "IBM Plex Sans Arabic", sans-serif !important;
  color: var(--text-color);
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
  color: var(--text-color);
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  // font-family: var(--head-font);
}

a {
  text-decoration: none;
  color: var(--text-color);
}

ul {
  list-style: none;
}

#root,
#__next {
  isolation: isolate;
}

/********************************************************************************/

/********************* Adjusting Font Sizes For All Screens *********************/
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 5px 0;
}

h1 {
  font-size: $h1;
}

h2 {
  font-size: $h2;
}

h3 {
  font-size: $h3;
}

h4 {
  font-size: $h4;
}

h5 {
  font-size: $h5;
}

h6 {
  font-size: $h6;
}

p {
  line-height: 25px;
  font-size: $p;
}

/****** Medium Screen ******/
@include md {
  span,
  p {
    font-size: 15px;
  }
}

@include xs {
  span,
  p {
    font-size: 14px;
  }
}

img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  vertical-align: middle;
  object-fit: cover;
}

/*****************************************\ Selection Reset \**************************************/

::selection {
  background: $mainColor;
  color: #fff;
}

::-moz-selection {
  background: $mainColor;
  color: #fff;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background: var(--secondary-background);
}

::-webkit-scrollbar-thumb {
  width: 7px;
  height: 7px;
  border-radius: 0px;
  background-color: rgba($color: $mainColor, $alpha: 1);
}

#ar {
  * {
    direction: rtl;
    text-align: right;
  }
}

#en {
  * {
    direction: ltr;
    text-align: left;
  }
}

// HOME MULTI CAROUSEL COMPONENT STYLES
.react-multiple-carousel__arrow--left {
  left: -10px !important;
}

.react-multiple-carousel__arrow--right {
  right: -10px !important;
}

// QUICK VIEW MULTI CAROUSEL COMPONENT STYLES
.thumbnails {
  // padding: 0 50px;
  .react-multiple-carousel__arrow--left {
    background-color: var(--secondary-background);
    // background-color: transparent;
    height: 100%;
    border-radius: 0;
    padding: 0;
    margin: 0;
    &::before {
      font-size: 15px;
      font-weight: bold;
      border-radius: 0;
      text-align: center !important;
      color: var(--text-color);
      margin: 2px;
      left: -2px;
    }
  }

  .react-multiple-carousel__arrow--right {
    background-color: var(--secondary-background);
    // background-color: transparent;
    height: 100%;
    border-radius: 0;
    padding: 0;
    width: 5px;
    &::before {
      font-size: 15px;
      font-weight: bold;
      border-radius: 0;
      text-align: center !important;
      color: var(--text-color);
      right: -2px;
    }
  }
}

// CATEGORY PAGE SLIDER GLOBAL STYLES
.MuiSlider-track {
  height: 6px;
}

// PAGINATION SELECTED PAGE GLOBAL STYLES
.pagination {
  button {
    // border: 1px solid #e2e2e2;
    box-shadow: none;
    color: var(--text-color);
    &.Mui-selected {
      background-color: var(--main-color);
      color: #fff;
      border: 1px solid var(--main-color);
      &:hover {
        background-color: var(--main-color);
        color: #fff;
        border: 1px solid var(--main-color);
      }
    }
    &:hover {
      background-color: var(--main-color);
      color: #fff;
      border: 1px solid var(--main-color);
    }
  }
  @include xs {
    transform: scale(0.9);
  }
}

// SWEET ALERT GLOBAL STYLES
body.swal2-shown {
  padding: 0 !important;
}

button.swal2-confirm {
  background-color: var(--main-color);
  padding: 10px 20px;
  border: 1px solid var(--main-color);
  outline: none;
  color: #fff;
  border-radius: 5px;
  font-size: 15px;
  margin: 0 5px;
  cursor: pointer;
  &:hover {
    background-color: #fff;
    color: var(--main-color);
  }
}

button.swal2-cancel {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 10px 20px;
  position: relative;
  color: var(--text-color) !important;
  top: 3px;
  border-radius: 20px;
  font-size: 17px;
  margin: 0 5px;
  font-weight: 500;
  cursor: pointer;
  color: var(--main-color);
  &:hover {
    background-color: #fff;
    color: var(--main-color);
    text-decoration: underline;
  }
}

@include sm {
  .swal2-popup {
    .swal2-icon {
      transform: scale(0.9);
    }
    h2 {
      font-size: 24px;
    }
    div {
      font-size: 18px;
    }
  }
}

@include xs {
  .swal2-popup {
    .swal2-icon {
      transform: scale(0.8);
    }
    h2 {
      font-size: 20px;
    }
    div {
      font-size: 17px;
    }
  }
}

.Toastify__toast-container {
  z-index: 9999999999999 !important;
}

// MUI5 DATE PICKER GLOBAL STYLES
.MuiCalendarPicker-root {
  background-color: var(--secondary-background);
  button {
    background-color: var(--default-background);
    color: var(--text-color);
  }
  span {
    color: var(--text-color);
  }
}

.rdrCalendarWrapper {
  position: absolute;
  right: 0;
  top: 50px;
  z-index: 9;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
}

.ar .rdrCalendarWrapper {
  right: unset;
  left: 0;
  direction: ltr !important;

  & * {
    direction: ltr !important;
  }
}

.empty {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 250px;
  height: 80%;
  img {
    width: 100px;
    height: 100px;
    opacity: 0.5;
    margin-bottom: 10px;
  }
  p {
    color: var(--text-color);
    opacity: 0.9;
    font-size: 1rem;
  }
  @include sm {
    img {
      width: 80px;
      height: 80px;
    }
    p {
      font-size: 0.9rem;
    }
  }
}

.react-tagsinput {
  background-color: transparent !important;
  border: none !important;
}
.react-tagsinput-tag {
  background-color: var(--main-color) !important;
  border: none;
  color: #fff;
}

.tags-error {
  span {
    input {
      border-color: var(--error-alert) !important;
    }
  }
}

.idString {
  cursor: pointer;
}
