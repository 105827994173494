.btn {
  margin-top: 10px !important;
}

.loading {
  background-color: #fff!important;
  border: 1px solid var(--main-color) !important;
  cursor: auto!important;
  display: inline-flex!important;
  justify-content: center!important;
  margin-top: 10px !important;

  .loader {
    animation: spinningColor 0.5s linear infinite;
    border: 2px double var(--main-color);
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }
  
  @keyframes spinningColor {
    0% {
      transform: rotate(360deg);
      border-top: 2px dashed var(--bg-main);
      border-bottom: 2px dashed var(--bg-main);
    }
    25% {
      border-top: 2px dashed var(--bg-main);
      border-bottom: 2px dashed var(--bg-main);
    }
    50% {
      border-top: 2px dashed var(--bg-main);
      border-bottom: 2px dashed var(--bg-main);;
    }
    75% {
      border-top: 2px dashed var(--bg-main);
      border-bottom: 2px dashed var(--bg-main);
    }
    100% {
      border-top: 2px dashed var(--bg-main);
      border-bottom: 2px dashed var(--bg-main);
    }
  }
}