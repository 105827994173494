@import '../../styles/mixins';

.welcome {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: static;
  @include xs {
    display: block;
    .welcome__date  {
      margin-top: 10px;
    }
  }
  .welcome__text {
    h6 {
      font-size: 17px;
    }
    p {
      font-size: 15px;
      opacity: 0.8;
    }
  }
  .welcome__date {
    position: relative;
    input {
      background-color: var(--secondary-background);
      padding: 10px;
      color: var(--text-color);
      border-radius: 5px 0 0 5px;
      border: none;
      box-shadow: 0 0 2px rgb(0 0 0 / 5%);
      padding: 10px 15px;
      cursor: pointer;
      outline: none;
      font-size: 14px;
      min-height: 42px;
    }

    & > i {
      padding: 8px 15px;
      cursor: pointer;
      outline: none;
      font-size: 16px;
      background-color: var(--main-color);
      color: #fff;
      border-radius: 0 5px 5px 0;
      box-shadow: 0 0 2px rgb(0 0 0 / 5%);
      min-height: 42px;
    }

    button {
      border-radius: 5px;
      svg {
        color: #fff;
      }
    }
    fieldset {
      border-color: transparent !important;
      box-shadow: 0 1px 2px rgb(56 65 74 / 10%);
    }
    &.ar {
      input {
        border-radius: 0 5px 5px 0;
      }
      & > i {
        border-radius: 5px 0 0 5px;
      }
    }
  }
}

.topAnalytics {
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-auto-flow: dense;
  gap: 1rem;
  &.special {
    // grid-template-columns: repeat(auto-fit, minmax(102px, 1fr));
    grid-template-columns: repeat(12, 1fr);
    .topAnalytics__section {
      grid-column: span 3;
    }
    @include sm {
      // grid-template-columns: repeat(12, 1fr);
      .topAnalytics__section {
        grid-column: span 6;
      }
    }
    @include xs {
      .topAnalytics__section {
        grid-column: span 12;
      }
    }
  }
  @include sm {
    grid-template-columns: repeat(12, 1fr);
  }
  .topAnalytics__section {
    grid-column: span 2;
    @include lg {
      grid-column: span 3;
    }
    @include sm {
      grid-column: span 6;
    }
    @include xs {
      grid-column: span 12;
    }
    .topAnalytics__section_part {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      p {
        font-size: 0.9rem;
        opacity: 0.8;
        text-transform: uppercase;
      }
      span {
        color: var(--secondary-color);
        font-weight: 600;
      }
      .price {
        font-size: 1.3rem;
        margin-top: 10px;
        font-weight: 550;
        span {
          font-size: 1.3rem;
          margin-top: 10px;
          font-weight: 550;
          color: var(--text-color) !important;
        }
      }
      .item {
        font-size: 1.1rem;
        margin-top: 10px;
        font-weight: 550;
      }
      .smallTitle {
        font-size: 0.9rem;
        color: var(--main-color) !important;
        font-weight: 400 !important;
      }
      .icon {
        width: 50px;
        height: 45px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.3rem;
      }
    }
    &:nth-of-type(odd) {
      .topAnalytics__section_part {
        span {
          color: var(--main-color);
          font-weight: 600;
        }
        .icon {
          background-color: rgba(#40739e, 0.3);
          color: var(--main-color);
        }
      }
    }
    &:nth-of-type(even) {
      .topAnalytics__section_part {
        span {
          color: var(--secondary-color);
          font-weight: 600;
        }
        .icon {
          background-color: rgba(#ff7300, 0.3);
          color: var(--secondary-color);
        }
      }
    }
  }
}