.wrapper {
  background-color: var(--default-background);
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  img {
    width: 60px;
    height: 60px;
    margin: 0px auto 0px;
    position: relative;
    left: 0px;
  }
  h4 {
    text-align: center !important;
    display: flex;
    align-items: center;
    font-family: 'Cairo', sans-serif !important;
    span {
      display: block;
      background-color: var(--secondary-color);
      color: #000;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: grid;
      place-items: center;
      font-size: 1.3rem;
      margin: 0 3px;
      position: relative;
      &.arabicLang {
        margin: 0 2px;
        top: -1px;
      }
    }
  }
}
.lds_ellipsis {
  position: relative;
  left: -45px;
  div {
    position: absolute;
    top: 50%;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: var(--main-color);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    &:nth-child(1) {
      left: 8px;
      animation: lds-ellipsis1 0.6s infinite;
    }
    &:nth-child(2) {
      left: 8px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    &:nth-child(3) {
      left: 32px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    &:nth-child(4) {
      left: 56px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    &:nth-child(5) {
      left: 72px;
      animation: lds-ellipsis3 0.6s infinite;
    }
    @keyframes lds-ellipsis1 {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }
    @keyframes lds-ellipsis3 {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
      }
    }
    @keyframes lds-ellipsis2 {
      0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(24px, 0);
      }
    }
  }
}