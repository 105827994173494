.allProductsFilter {
  border-radius: 5px;
  box-shadow: 0 1px 2px rgb(56 65 74 / 10%);
  overflow: hidden;

  .allProductsFilter__filter {
    border-bottom: 1px solid var(--border-color);

    .allProductsFilter__filter_head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 15px;
      p {
        font-weight: 400;
        &.clear {
          font-size: 0.9rem;
          text-decoration: underline;
          color: var(--main-color);
          cursor: pointer;
        }
      }
    }

    .allProductsFilter__filter_box {
      padding: 0px 10px 10px;
      .filtered {
        background-color: var(--main-color);
        padding: 0px 5px 3px;
        color: #fff;
        display: inline-block;
        border-radius: 5px;
        margin: 5px;
        span {
          padding-right: 10px;
          border-right: 1px solid rgb(221, 221, 221);
          font-size: 0.8rem;
        }
        i {
          padding: 2px 8px;
          font-size: 0.75rem;
          cursor: pointer;
        }
        &.ar {
          span {
            padding-right: unset;
            padding-left: 10px;
            border-right: unset;
            border-left: 1px solid rgb(221, 221, 221);
          }
          // i {
          //   padding: 2px 8px;
          // }
        }
      }

      p {
        font-weight: 400;
        text-align: center !important;
        font-size: 0.9rem;
      }
    }

  }

  .allProductsFilter__products {
    padding: 10px 7px;
    border-bottom: 1px solid var(--border-color);
    .allProductsFilter__filter_head {
      margin-bottom: 10px;
      p {
        font-weight: 400;
        font-size: 0.85rem;
        opacity: 0.8;
      }
    }
    p {
      margin: 0;
      font-size: 0.9rem;
      cursor: pointer;
    }
  }

  .allProductsFilter__price {
    padding: 10px 15px;
    border-bottom: 1px solid var(--border-color);

    .allProductsFilter__filter_head {
      margin-bottom: 10px;
      p {
        font-weight: 400;
        font-size: 0.85rem;
        opacity: 0.8;
      }
    }

    .slider {
      .slider__range {
        display: flex;
        justify-content: center;
        margin-bottom: 15px;
        p {
          background-color: var(--main-color-light);
          padding: 2px 20px;
          margin: 0 10px;
          font-weight: 500;
          font-size: 0.9rem;
          color: var(--main-color);
        }
      }
    }
  }

  .allProductsFilter__brands, .allProductsFilter__discount, .allProductsFilter__colors {
    padding: 10px 15px;
    border-bottom: 1px solid var(--border-color);
  }
  .allProductsFilter__colors {
    border-bottom: none;
    .allProductsFilter__colors_head {
      margin-bottom: 20px;
      p {
        font-weight: 400;
        font-size: 0.8rem;
        opacity: 0.8;
      }
    }

    .colorsBox {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      .color {
        display: inline-block;
        width: auto;
        min-width: 30px;
        height: 30px;
        margin: 0 5px;
        cursor: pointer;
        box-shadow: 0 5px 5px rgb(30 32 37 / 10%);
        &.selected {
          border: 1px solid #000;
          position: relative;
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 1px solid #fff;
          }
        }
      }

      .emptyColors {
        font-size: 0.9rem;
      }
    }
  }

}