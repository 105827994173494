@import "../../styles/extends.scss";

.contactDetails__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  h6 {
    font-size: 16px;
    font-weight: 700;
  }
  p {
    span {
      font-weight: 400;
    }
  }
}
.detailsTable {
  padding: 10px 20px;
  border-radius: 5px;
  h3 {
    text-align: center !important;
    margin-bottom: 20px;
  }
  .field {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
    label {
      font-weight: bold;
      margin-bottom: 5px;
    }
    span {
      background-color: var(--default-background);
      padding: 10px 20px;
      border-radius: 5px;
    }
  }
  .btn {
    display: flex;
    justify-content: center;
    button {
      margin-top: 20px;
      margin-bottom: 10px;
      @extend %btn;
      background-color: var(--error-alert-light);
      color: var(--error-alert);
      border: 1px solid var(--error-alert);
      &:hover {
        background-color: var(--error-alert);
        border-color: var(--error-alert);
        color: #fff;
      }
    }
  }
}
