@import '../../../styles/mixins.scss';

.mainAnalytics {
  .analytics__yearChart {
    .revenue {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .revenueFilter {
        display: flex;
        div {
          width: auto;
          padding: 2px 8px;
          height: 30px;
          border: 1px solid var(--border-color);
          margin: 0 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.9rem;
          cursor: pointer;
          border-radius: 5px;
          color: var(--main-color);
          &:hover {
            background-color: var(--main-color);
            color: #fff;
            border: 1px solid var(--main-color);
          }
          &.selected {
            background-color: var(--main-color);
            color: #fff;
            border: 1px solid var(--main-color);
          }
        }
      }
    }

    .analytics__yearChart_data {
      margin-bottom: 20px;
      border-top: 1px dashed var(--border-color);
      border-bottom: 1px dashed var(--border-color);
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      div {
        grid-column: span 4;
        padding: 10px 0;
        border-right: 1px dashed var(--border-color);
        @include xs {
          grid-column: span 6;
        }
        p {
          text-align: center !important;
          opacity: 0.8;
          font-size: 14px;
          &.price {
            font-weight: 550;
            font-size: 1.2rem;
            opacity: 1;
          }
        }
        &:last-of-type {
          border: none;
        }
        &:nth-of-type(odd) {
          .price {
            color: var(--main-color);
          }
        }
        &:nth-of-type(even) {
          .price {
            color: var(--secondary-color);
          }
        }
      }
    }

    .yearChart {
      overflow: hidden;
    }
  }

  .map {
    height: 100%;

    svg {
      fill: #b0c9dd;
      max-height: 300px;
      min-height: 300px;
      margin: auto;
      cursor: pointer;
      path {
        outline: none;
        &:hover {
          fill: var(--main-color);
        }
      }
    }

    .info {
      width: 80%;
      margin: 30px auto 0;
      @include xl {
        width: 90%;
      }
      .noData {
        font-weight: 600;
        text-align: center !important;
        margin-top: 30px;
      }
      div {
        margin-bottom: 10px;
        p {
          opacity: 0.8;
          font-size: 0.95rem;
        }
      }
    }
  }
}