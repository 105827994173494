.customerSupport {
  .customerSupport__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    h6 {
      font-size: 1rem;
      font-weight: 700;
    }
    p {
      span {
        font-weight: 400;
      }
    }
  }

  .customerSupport__tabs {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    p {
      padding: 10px 20px;
      border-radius: 5px;
      font-weight: 500;
      cursor: pointer;
      &.selected {
        background-color: var(--main-color-light);
        color: var(--main-color);
      }
    }
  }

  .customerSupport__currentTab {
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgb(56 65 74 / 10%);
  }
}