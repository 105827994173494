@import '../../../../styles/mixins';
@import '../../../../styles/extends';

.orderedItem {
  border: 1px solid var(--border-color);
  border-radius: 5px;
  margin-bottom: 10px;

  .head {
    background-color: var(--default-background);
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px 5px 0 0;

    @include md {
      display: block;
      .head__part {
        margin-bottom: 10px;
      }
    }

    .head__part {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 2rem;
      p {
        font-size: 14px;
        &.top {
          font-size: 13px;
          font-weight: 600;
        }
        &.view {
          font-size: 13px;
          font-weight: 600;
          color: var(--main-color);
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      
      &.includeView {
        @include xs {
          display: block;
          .view {
            margin-top: 10px;
          }
        }
      }
    }

  }

  .body {
    padding: 20px;
    h6 {
      margin-bottom: 20px;
      font-size: 18px;
    }

    .body__order {
      display: grid;
      align-items: center;
      justify-content: space-between;
      grid-template-columns: repeat(12, 1fr);
      margin-bottom: 50px;

      &:last-of-type {
        margin-bottom: 0;
      }

      @include md {
        display: block;
      }

      .body__order_img {
        display: flex;
        align-items: center;
        grid-column: span 12;
        @include xs {
          flex-wrap: wrap;
          justify-content: center;
        }
        img {
          min-width: 80px;
          width: 80px;
          height: 80px;
          object-fit: contain;
          margin-right: 15px;
          &.ar {
            margin-right: unset;
            margin-left: 15px;
          }
          @include lg {
            margin-right: 15px;
          }
          @include md {
            margin-right: 15px;
            min-width: 130px;
            width: 130px;
          }
          @include xs {
            margin-bottom: 15px;
          }
        }
        .body__order_info {
          @include xs {
            width: 90%;
            flex: 0 0 90%;
          }
        }
        h6 {
          margin: 0;
          font-size: 1rem;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
          @include md {
            font-size: 16px;
          }
          @include xs {
            font-size: 14px;
          }
        }
        p {
          font-size: 0.9rem;
          opacity: 0.8;
          @include xs {
            font-size: 13px;
          }
        }
        span {
          font-size: 13px;
          font-weight: bold;
          margin-top: 5px;
          padding: 0 3px;

          @include xs {
            font-size: 12px;
          }
        }
        & > span::before {
          content: '- ';
        }
        button {
          @extend %mainBtn;
          font-size: 13px;
          padding: 6px 20px;
          border-radius: 5px;
          margin-top: 10px;
          display: block;
        }
      }

      .body__order_actions {
        display: flex;
        flex-direction: column;
        text-align: center !important;
        grid-column: 10 / span 6;
        @include xl {
          grid-column: 9 / span 6;
        }
        @include sm {
          margin-top: 10px;
        }
        button {
          margin: 8px 0px;
          @extend %mainBtn;
          font-size: 13px;
          max-width: 300px;
          border-radius: 5px;
          &.review {
            background-color: transparent;
            color: var(--main-color);
          }
          @include xs {
            padding: 8px 10px;
            width: 90%;
            max-width: 90%;
            margin: 5px auto;
          }
        }
      }

    }
  }
}