@import '../../../styles/mixins';
@import '../../../styles/extends';

.createProductsOptions {
  .createProductsOptions__publish {
    margin-bottom: 20px;
    padding: 10px 15px;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgb(56 65 74 / 10%);
    h6 {
      font-size: 1.1rem;
      border-bottom: 1px solid var(--border-color);
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
    label {
      font-size: 0.9rem;
    }
    .visibility {
      margin-top: 10px;
    }
  }

  .createProductsOptions__category {
    margin-bottom: 20px;
    padding: 10px 15px;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgb(56 65 74 / 10%);
    h6 {
      font-size: 1.1rem;
      border-bottom: 1px solid var(--border-color);
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
    label {
      font-size: 0.9rem;
      &.disabled {
        opacity: 0.5
      }
    }
    input {
      @extend %input;
      width: 100%;
      margin-bottom: 10px;
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  .createProductDetails__brand, .createProductDetails__tags {
    margin-bottom: 20px;
    padding: 10px 15px;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgb(56 65 74 / 10%);
    h6 {
      font-size: 1.1rem;
      border-bottom: 1px solid var(--border-color);
      padding-bottom: 10px;
      margin-bottom: 10px;
    }

    input {
      @extend %input;
      width: 100%;
    }

    p {
      color: var(--error-alert);
      font-size: 0.9rem;
      display: block;
      margin-top: 0px;
    }
  }
  
  .createProductsOptions__desc {
    margin-bottom: 20px;
    padding: 10px 15px;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgb(56 65 74 / 10%);
    h6 {
      font-size: 1.1rem;
      border-bottom: 1px solid var(--border-color);
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
    label {
      font-size: 0.9rem;
      display: block;
    }
    textarea {
      @extend %input;
      width: 100%;
      height: 120px;
      &.error {
        border-color: var(--error-alert);
      }
    }

    p {
      color: var(--error-alert);
      font-size: 0.9rem;
      display: block;
      margin-top: 0px;
    }
  }
}