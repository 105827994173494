@import '../../../styles/extends.scss';

.allProductsTable {
  border-radius: 5px;
  box-shadow: 0 1px 2px rgb(56 65 74 / 10%);
  overflow-x: scroll !important;

  .allProductsTable__head {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    button {
      @extend %btn;

      i {
        margin-right: 5px;
        font-size: 0.8rem;
      }
    }

    .allProductsTable__head_search {
      position: relative;

      input {
        @extend %input;
        padding: 7px 15px 7px 35px;
      }

      i {
        position: absolute;
        top: 20%;
        left: 10px;
        opacity: 0.7;
      }
    }
  }

  .deleteSelected {
    display: flex;
    justify-content: flex-end;
    font-size: 0.95rem;

    span {
      margin: 0 10px;
      color: var(--secondary-color);
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .allProductsTable__tabs {
    display: flex;
    margin: 0 10px;

    p {
      padding: 7px 15px;
      font-weight: 450;
      cursor: pointer;
      font-size: 0.9rem;
      display: flex;

      &.active {
        border-bottom: 2px solid var(--main-color);
      }

      span {
        background-color: var(--alert-light);
        color: var(--alert);
        padding: 5px;
        border-radius: 50%;
        font-size: 0.7rem;
        margin-left: 5px;
        width: 25px;
        height: 25px;
        display: grid;
        place-items: center;

        &.ar {
          margin-left: unset;
          margin-right: 5px;
        }
      }
    }
  }

  .allProductsTable__table {
    table {
      min-width: 850px;

      thead {
        border-top: 1px solid var(--border-color);
        background-color: var(--default-background);

        tr {
          border: none;

          th {
            border: none;
            padding: 15px 10px 15px;
            color: var(--text-color);
            font-weight: 450;
            opacity: 0.8;
            font-size: 0.95rem;
            cursor: pointer;

            span {
              display: flex;
              justify-content: space-between;
            }
          }
        }
      }

      tbody {
        tr {
          border: none;
          cursor: pointer;

          &:hover {
            background-color: var(--hover-color);
          }

          td {
            border: none;
            padding: 8px 10px 8px;
            color: var(--text-color);
            border-top: 1px solid var(--border-color);
            border-bottom: 1px solid var(--border-color);
            font-weight: 450;
            font-size: 0.8rem;

            .product {
              display: flex;
              align-items: center;

              img {
                min-width: 55px;
                max-width: 55px;
                height: 60px;
                object-fit: contain;
              }

              .info {
                padding: 0 10px;

                p {
                  font-size: 0.9rem;
                  margin: 0;

                  &.category {
                    opacity: 0.8;
                    font-size: 0.85rem;
                  }
                }
              }
            }

            span.outStock {
              padding: 3px 5px;
              border-radius: 5px;
              color: var(--error-alert);
              background-color: var(--error-alert-light);
              font-size: 0.8rem;
            }

            span.rate {
              i {
                color: var(--alert);
                padding: 0 5px;
              }
            }

            button {
              background-color: var(--main-color-light);
              padding: 5px;
              font-size: 20px;
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 5px;

              &:hover {
                background-color: var(--main-color);
                color: #fff;
              }
            }
          }
        }
      }
    }
  }

  .allProductsTable__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;

    p {
      color: var(--text-color);
      font-weight: 450;
      font-size: 0.95rem;
    }

    @include xs {
      flex-direction: column;

      p {
        margin-bottom: 5px;
      }
    }
  }

}