@import '../../../styles/mixins';

.deactivateSettings {
  padding: 20px;
  border-radius: 5px;
  animation: show 0.5s forwards 1;
  box-shadow: 0 1px 2px rgb(56 65 74 / 10%);
  @keyframes show {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  h6 {
    @include xs {
      font-size: 1.1rem;
    }
  }

  p {
    opacity: 0.8;
    margin-top: 10px;
  }

  button {
    padding: 6px 30px;
    margin-top: 10px;
    border-radius: 5px;
    background-color: var(--error-alert-light);
    color: var(--error-alert);
    border: 1px solid var(--error-alert);
    cursor: pointer;
    &:hover {
      background-color: var(--error-alert);
      color: #fff;
    }
  }
}