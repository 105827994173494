@import '../../../../styles/extends';

.order {
  padding: 10px;
  border-radius: 5px;

  .order__head {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    button {
      font-size: 0.85rem;
      padding: 5px 10px;
      border-radius: 5px;
      cursor: pointer;
      background-color: var(--main-color-light);
      color: var(--main-color);
      border: 1px solid var(--main-color);
      margin: 0 5px;
      @include xs {
        font-size: 0.7rem;
        padding: 4px 8px;
      }
      &:hover {
        background-color: var(--main-color);
        color: #fff;
      }
    }
    button.cancel {
      background-color: var(--error-alert-light);
      color: var(--error-alert);
      border: 1px solid var(--error-alert);
      &:hover {
        background-color: var(--error-alert);
        color: #fff;
      }
    }
    button {
      @extend %btn;
    }
  }

  .order__table {
    overflow-x: scroll;
    table {
      min-width: 750px;
      thead {
        background-color: var(--default-background);
        tr {
          border: none;
          th {
            border: none;
            padding: 15px 10px 15px;
            color: var(--text-color);
            font-weight: 450;
            opacity: 0.8;
            font-size: 0.95rem;
            cursor: pointer;
            span {
              display: flex;
              justify-content: space-between;
            }
          }
        }
      }
      tbody {
        tr {
          border: none;
          &:hover {
            background-color: var(--hover-color);
          }
          td {
            border: none;
            padding: 8px 10px 8px;
            color: var(--text-color);
            font-weight: 450;
            font-size: 0.9rem;
            padding-top: 10px;
            padding-bottom: 10px;
            .product {
              display: flex;
              img {
                min-width: 70px;
                max-width: 70px;
                height: 70px;
                display: block;
                object-fit: contain;
              }
              p {
                font-weight: 600;
                color: var(--main-color);
                cursor: pointer;
                &:hover {
                  text-decoration: underline;
                }
              }
              span, p {
                margin: 0 10px;
                display: block;
              }
              span {
                display: flex;
              }
              .colorBall {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                margin: 4px 0;
              }
            }
            span.outStock {
              padding: 3px 5px;
              border-radius: 5px;
              color: var(--error-alert);
              background-color: var(--error-alert-light);
              font-size: 0.8rem;
            }
            span.rate {
              i {
                color: var(--alert);
                padding: 0 5px;
              }
            }
            span {
              span {
                width: 1rem;
              }
            }
            .total {
              text-align: center !important;
            }
            button {
              background-color: var(--main-color-light);
              padding: 5px;
              font-size: 20px;
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 5px;
              &:hover {
                background-color: var(--main-color);
                color: #fff;
              }
            }
          }
        }
      }
    }
  }

  .order__calcs {
    padding: 10px 0;
    border-top: 1px dashed var(--border-color);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .calc {
      display: flex;
      justify-content: space-between;
      padding: 5px 10px;
      width: 100%;
      &.ar {
        p:first-of-type {
          margin-left: 100px;
        }
      }
      p:first-of-type {
        margin-right: 100px;
      }
      p {
        font-size: 0.9rem;
      }
      &.last {
        border-bottom: 1px dashed var(--border-color);
      }
    }
  }
}

.orderStatus {
  padding: 20px;
  margin-top: 20px;
  border-radius: 5px;

  .orderStatus__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 15px;
    h6 {
      font-size: 1.1rem;
      font-weight: 600;
    }

    .orderStatus__btns {
      button {
        font-size: 0.85rem;
        padding: 5px 10px;
        border-radius: 5px;
        cursor: pointer;
        background-color: var(--main-color-light);
        color: var(--main-color);
        border: 1px solid var(--main-color);
        @include xs {
          font-size: 0.7rem;
          padding: 4px 8px;
        }
        &:hover {
          background-color: var(--main-color);
          color: #fff;
        }
      }
    }
  }

  .stepper {
    .step {
      &:last-of-type {
        .content {
          border: none;
        }
      }
      &.completed {
        .label {
          i {
            background-color: var(--main-color);
            color: rgb(236, 236, 236);
          }
        }
      }
      &.ar {
        &:last-of-type {
          .content {
            border: none;
          }
        }
        .label {
          i {
            margin-right: unset;
            margin-left: 10px;
          }
        }
        .content {
          border-left: unset;
          padding: 10px 30px;
          transform: translateX(-20px);
          border-right: 2px dashed var(--border-color);
        }
      }
      .label {
        display: flex;
        align-items: center;
        i {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: var(--main-color-light);
          display: grid;
          place-items: center;
          color:  var(--main-color);
          border: 1px solid var(--main-color);
          margin-right: 10px;
        }
        p {
          font-weight: 600;
        }
      }
      .content {
        padding: 10px 30px;
        transform: translateX(20px);
        border-left: 2px dashed var(--border-color);
        div {
          margin-bottom: 10px;
        }
        p {
          i {
            font-size: 0.8rem;
          }
        }
        span {
          font-size: 0.8rem;
          opacity: 0.8;
        }
      }
    }
  }
}