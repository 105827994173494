.general {
  .general__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    h6 {
      font-size: 1rem;
      font-weight: 700;
    }
    p {
      span {
        font-weight: 400;
      }
    }
  }

  .general__welcome {
    margin-bottom: 30px;
  }

  .general__tabs {
    display: flex;
    p {
      padding: 10px 20px;
      cursor: pointer;
      border-radius: 5px 5px 0 0;
      transform: translateY(1px);
      position: relative;
      z-index: 2;
      &.selected {
        background-color: var(--secondary-background);
        color: var(--main-color);
        font-weight: 500;
        border: 1px solid var(--border-color);
        border-bottom: none;
      }
    }
  }
}