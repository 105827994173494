.inputField {
  display: block !important;
  input {
    display: block;
    &.error {
      border-color: var(--error-alert) !important;
    }
  }
  p {
    color: var(--error-alert);
    font-size: 0.9rem;
    display: block;
  }
}