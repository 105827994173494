input[type="checkbox"] {
  appearance: none;
  width: 17px;
  height: 17px;
  margin-top: -.125em;
  margin-right: .375em;
  vertical-align: middle;
  border: 2px solid var(--custom);
  border-radius: 0.25rem;
  outline: 0;
  background-color: transparent;
  color: var(--main-color);
  font-weight: 400;
  transition: all 0.2s ease-in-out;
  margin: 0 10px;
  cursor: pointer;
}

input[type="checkbox"]:checked {
  border-color: var(--main-color);
  background-color: var(--main-color);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 22 22' fill='none' stroke='%23FFF' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
}

input[type="checkbox"]:indeterminate {
  border-color: var(--main-color);
  background-color: var(--main-color);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 24 24' fill='none' stroke='%23FFF' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='5' y1='12' x2='19' y2='12'%3E%3C/line%3E%3C/svg%3E");
  background-repeat: no-repeat;
}
