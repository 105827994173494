@import '../../../styles/mixins.scss';

.ordersTable, .ordersTable2 {
  margin-top: 20px;
  max-width: 100%;
  // height: 97%;
  overflow-x: scroll !important;
  padding: 10px 20px !important;

  &.ordersTable2 {
    overflow: hidden !important;
    padding: 0px !important;
    height: 81% !important;
  }

  .ordersTable_head, .ordersTable__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    p {
      font-weight: 450;
    }
  }

  .ordersTable_head {
    button {
      color: var(--main-color);
      font-size: 0.9rem;
      padding: 3px 10px;
      border-radius: 5px;
      border: none;
      background-color: var(--main-color-light);
      cursor: pointer;
      i {
        margin: 0 5px;
      }
    }
  }

  table {
    min-width: 620px;
    thead {
      background-color: var(--default-background);
      tr {
        border: none !important;
        th {
          border: none !important;
          color: var(--text-color);
        }
      }
    }
    tbody {
      tr {
        cursor: pointer;
        td {
          border: none !important;
          font-weight: 450;
          color: var(--text-color);
          padding: 0 13px;
          div {
            display: flex;
            align-items: center;
            img {
              width: 35px;
              height: 35px;
              border-radius: 50%;
              margin: 0 5px;
            }
          }
          span {
            padding: 3px 7px;
            border-radius: 5px;
          }
          span.Completed, span.Delivered, span.Shipped {
            color: var(--success);
            background-color: var(--success-light);
          }
          span.Processing, span.Ready, span.onWay {
            color: var(--alert);
            background-color: var(--alert-light);
          }
          span.Refunded, span.Cancelled {
            color: var(--error-alert);
            background-color: var(--error-alert-light);
          }
        }
        &:hover {
          background-color: var(--hover-color);
        }
      }
    }
  }

  .ordersTable__footer {
    margin-bottom: 0;
    margin-top: 20px;
    p {
      font-size: 0.95rem;
    }
    @include xl {
      p {
        font-size: 0.90rem;
      }
    }
    @include lg {
      p {
        font-size: 0.90rem;
      }
    }
    @include xs {
      display: block;
      p {
        margin-bottom: 10px;
        text-align: center !important;
      }
      .pagination {
        display: flex;
        justify-content: center;
      }
    }
  }

}