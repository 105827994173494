@import '../../styles/mixins';

.returnDetails__user__loading {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 1rem;
  div {
    margin-bottom: 20px;
    grid-column: span 2;
    @include lg {
      grid-column: span 5;
      margin-bottom: 0px;
      &:last-of-type {
        margin-bottom: 20px;
      }
    }
    @include sm {
      grid-column: span 5;
      margin-bottom: 0px;
      &:last-of-type {
        margin-bottom: 20px;
      }
    }
    @include xs {
      grid-column: span 10;
      margin-bottom: 0px;
      &:last-of-type {
        margin-bottom: 20px;
      }
    }
  }
}

.returnDetails {
  .returnDetails__user {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    gap: 1rem;
    margin-top: 10px;
    margin-bottom: 20px;

    div {
      grid-column: span 2;
      display: flex;
      align-items: center;
      background-color: var(--default-background);
      padding: 10px 20px;
      border-radius: 5px;
      border: 1px dashed var(--border-color);
      @include lg {
        grid-column: span 5;
      }
      @include md {
        grid-column: span 5;
      }
      @include sm {
        grid-column: span 5;
      }
      @include xs {
        grid-column: span 10;
      }
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
      span {
        margin: 0 10px;
        font-weight: 600;
        font-size: 0.9rem;
        p {
          font-size: 0.9rem;
        }
        &.name {
          font-size: 1rem;
        }
      }
    }
  }
}