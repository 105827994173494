// IMPORTING GLOBAL VARIABLES AND MIXINS
@import '../../../styles/mixins';
@import '../../../styles/extends';

.overlay {
  @keyframes showModal {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  animation: showModal 0.2s forwards ease-in-out 1;
  .area {
    background-color: var(--secondary-background);
    width: 500px;
    height: auto;
    max-height: 90%;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    position: relative;
    animation: show 0.3s forwards ease-in-out 1;
    @keyframes show {
      0% {
        transform: translateY(-30px);
        opacity: 0;
      }
      100% {
        transform: translateY(0px);
        opacity: 1;
      }
    }
    .area__wrapper {
      max-height: 90vh;
      padding: 30px;
    }
    @include lg {
      width: 500px;
    }
    @include md {
      width: 500px;
    }
    @include sm {
      width: 500px;
    }
    @include xs {
      width: 90%;
    }
    .close {
      position: absolute;
      top: -15px;
      right: -15px;
      width: 30px;
      height: 30px;
      cursor: pointer;
      background-color: #000;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.2s ease-in-out !important;
      &:hover {
        transform: rotate(90deg) !important;
      }
      &.ar {
        right: unset;
        left: -15px;
      }
    }

    .area__content {
      h3 {
        text-align: center !important;
        margin-bottom: 20px;
        font-size: 1.2rem;
        @include xs {
          font-size: 20px;
        }
      }


      .returnedItem {
        border: 1px solid var(--border-color);
        border-radius: 5px;
        margin-bottom: 10px;
      
        .head {
          background-color: var((--default-background));
          padding: 10px 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 5px 5px 0 0;
          @include xs {
            display: block;
            .head__part {
              margin-bottom: 10px;
            }
          }
          .head__part {
            display: flex;
            align-items: center;
            gap: 2rem;
            p {
              font-size: 14px;
              &.top {
                font-size: 13px;
                font-weight: 600;
              }
              &.view {
                font-size: 13px;
                font-weight: 600;
                color: var(--main-color);
                cursor: pointer;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
            &.includeView {
              @include xs {
                display: block;
                .view {
                  margin-top: 10px;
                }
              }
            }
          }
      
        }
      
        .body {
          margin-top: 5px;
          h6 {
            margin-bottom: 20px;
            font-size: 18px;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
      
        }
      }

      .actions {
        display: flex;
        justify-content: flex-end;
        margin-top: 30px;
        button {
          @extend %btn;
          padding: 8px 20px;
        }
        button:first-of-type {
          margin: 0 10px;
          background-color: var(--hover-color);
          border-color: var(--border-color);
          color: var(--text-color);
        }
      }
    }

  }
}