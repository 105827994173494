@import '../../../styles/extends';

.profileSettings {
  animation: show 0.5s forwards 1;
  @keyframes show {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  .profileSettings__information {
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgb(56 65 74 / 10%);
    .profileSettings__user {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        @include xs {
          width: 80px;
          height: 80px;
        }
      }
      .profileSettings__user_info {
        margin: 0 20px;
        h6 {
          font-size: 1.2rem;
          margin-bottom: 0;
          @include xs {
            font-size: 0.9rem;
          }
        }
        p {
          font-size: 0.9rem;
          opacity: 0.8;
          @include xs {
            font-size: 0.8rem;
          }
        }
        button {
          @extend %btn;
          margin-top: 5px;
        }
      }
    }
    h6 {
      font-size: 1.2rem;
      margin-bottom: 15px;
      @include xs {
        font-size: 1.1rem;
      }
    }
    .profileSettings__information_fields {
      .field {
        margin-bottom: 10px;
        div {
          margin-bottom: 5px;
          label {
            display: block;
            font-size: 0.9rem;
            margin-top: 5px
          }
          input {
            @extend %input;
            width: 100%;
            padding: 10px 20px;
          }
        }
      }
    }
  }
  .btn {
    display: grid;
    justify-content: flex-end;
    button {
      @extend %btn;
      margin-top: 10px;
      padding: 8px 30px;
    }
  }
}