@import '../../../styles/mixins';
@import '../../../styles/extends';

.userAddresses {
  overflow-x: scroll;
  box-shadow: 0 1px 2px rgb(56 65 74 / 10%);
  background-color: var(--secondary-background);
  border-radius: 5px;
  padding: 10px 0px;
  animation: show 0.3s ease-in-out;
  @keyframes show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .logistics__table_options {
    display: flex;
    grid-template-columns: repeat(12, 1fr);
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    padding: 5px 15px;
    @include xs {
      display: block;
    }
    .search {
      position: relative;
      i {
        position: absolute;
        top: 22%;
        left: 15px;
        opacity: 0.6;
      }
      input {
        padding-left: 40px;
      }
      &.ar {
        i {
          left: unset;
          right: 15px;
        }
        input {
          padding-left: unset;
          padding-right: 40px;
        }
      }
    }
    .logistics__table_countries {
      display: flex;
      align-items: center;
      a {
        i {
          width: 38px;
          height: 38px;
          background-color: var(--default-background);
          border-radius: 5px;
          display: grid;
          place-items: center;
          cursor: pointer;
          &:hover {
            background-color: var(--main-color);
            color: #fff;
          }
        }
      }
    }
    div {
      @include sm {
        grid-column: span 6;
      }
      @include xs {
        grid-column: span 12;
      }
    }
    input {
      @extend %input;
      height: 42px;
      width: 100%;
    }

    .date {
      & > div {
        border: 1px solid var(--border-color);
        padding-right: 10px;
        background-color: var(--default-background);
        border-radius: 5px;
        height: 42px;
        input {
          border: none;
          padding: 0 20px;
          height: 40px;
        }
      }
      svg {
        color: var((--text-color));
      }
      fieldset {
        border: none;
      }
      &.ar {
        padding-right: 0;
        padding-left: 10px;
        div {
          padding-right: 0;
        }
      }
    }
  }

  .deleteSelected {
    display: flex;
    justify-content: flex-end;
    font-size: 0.95rem;
    padding: 10px 10px 0 10px;
    span {
      margin: 0 10px;
      color: var(--secondary-color);
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .userAddresses__table {
    table {
      min-width: 750px;
      thead {
        border-top: 1px solid var(--border-color);
        background-color: var(--default-background);
        tr {
          border: none;
          th {
            border: none;
            padding: 15px 10px 15px;
            color: var(--text-color);
            font-weight: 450;
            opacity: 0.8;
            font-size: 0.95rem;
            cursor: pointer;
            span {
              display: flex;
              justify-content: space-between;
            }
          }
        }
      }
      tbody {
        tr {
          border: none;
          td {
            border: none;
            padding: 8px 10px 8px;
            color: var(--text-color);
            border-top: 1px solid var(--border-color);
            border-bottom: 1px solid var(--border-color);
            font-weight: 450;
            font-size: 0.8rem;
            .customer {
              display: flex;
              align-items: center;
              img {
                width: 35px;
                height: 35px;
                border-radius: 50%;
              }
              .info {
                padding: 0 10px;
                p {
                  font-size: 0.9rem;
                  margin: 0;
                  &.category {
                    opacity: 0.8;
                    font-size: 0.85rem;
                  }
                }
              }
            }
            span.status {
              padding: 2px 10px;
              border-radius: 5px;
              &.Active {
                background-color: var(--success-light);
                color: var(--success);
              }
              &.Block {
                background-color: var(--error-alert-light);
                color: var(--error-alert);
              }
            }
            button {
              background-color: var(--main-color-light);
              padding: 5px;
              font-size: 20px;
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 5px;
              &:hover {
                background-color: var(--main-color);
                color: #fff;
              }
            }
          }
        }
      }
    }
  }

  .userAddresses__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    p {
      color: var(--text-color);
      font-weight: 450;
      font-size: 0.95rem;
    }
    @include xs {
      flex-direction: column;
      p {
        font-size: 0.9rem;
        margin-bottom: 5px;
      }
    }
  }
}