@import '../../../styles/mixins';

.logsTab {
  padding: 20px;
  border-radius: 5px;
  animation: show 0.3s ease-in-out;
  @keyframes show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .datePicker {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include xs {
      display: block;
      .datePicker__date  {
        margin-top: 10px;
      }
    }
    .datePicker__text {
      h6 {
        font-size: 17px;
      }
      p {
        font-size: 15px;
        opacity: 0.8;
      }
    }
    .datePicker__date {
      position: relative;
      input {
        background-color: var(--default-background);
        padding: 10px;
        color: var(--text-color);
        border-radius: 5px 0 0 5px;
        border: none;
        box-shadow: 0 0 2px rgb(0 0 0 / 5%);
        padding: 10px 15px;
        cursor: pointer;
        outline: none;
        font-size: 14px;
        min-height: 42px;
        @include xs {
          max-width: 250px;
          width: 80%;
        }
      }
  
      & > i {
        padding: 8px 15px;
        cursor: pointer;
        outline: none;
        font-size: 16px;
        background-color: var(--main-color);
        color: #fff;
        border-radius: 0 5px 5px 0;
        box-shadow: 0 0 2px rgb(0 0 0 / 5%);
        min-height: 42px;
      }
  
      button {
        border-radius: 5px;
        svg {
          color: #fff;
        }
      }
      fieldset {
        border-color: transparent !important;
        box-shadow: 0 1px 2px rgb(56 65 74 / 10%);
      }
      &.ar {
        input {
          border-radius: 0 5px 5px 0;
        }
        & > i {
          border-radius: 5px 0 0 5px;
        }
      }
    }
  }

  h6 {
    font-size: 1.1rem;
  }

  .paginationWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    @include xs {
      flex-direction: column;
      p {
        font-size: 0.9rem;
        margin-bottom: 5px;
      }
    }
  }
}