@import '../../styles/mixins.scss';

.sidebar__list {
  padding-top: 15px;

  .sidebar__list_link {
    margin: 0 10px;
    transition: all 0.3s ease-in-out;
    position: relative;

    .link {
      padding: 2px 20px;
      margin-top: 10px;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 1rem;
      font-weight: 500;
      position: relative;

      &.selected {
        background-color: var(--hover-color);
      }

      &:hover {
        background-color: var(--hover-color);
      }

      .icon {
        pointer-events: none;

        i {
          font-size: 1.3rem;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
        }

      }

      .label {
        pointer-events: none;
        transition: opacity 0.1s ease-in-out;
      }

      &>i {
        position: absolute;
        top: 10px;
        right: 20px;
        transition: all 0.2s ease-in-out;
        pointer-events: none;

        &.ar {
          right: unset;
          left: 20px;
        }
      }
    }

    .subMenu {
      margin: 0;
      cursor: pointer;
      height: auto;
      overflow: hidden;
      max-height: 0;
      transition: all 0.3s ease-in-out;
      background-color: var(--background-light);
      position: relative;
      z-index: 9999;

      .wrapper {
        padding: 10px 0;
      }

      .dropLayer {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: transparent;
        z-index: -1;
      }

      a.active {
        li {
          color: var(--main-color);
          font-weight: 500;
          padding: 5px 10px 5px 20px;
        }
      }

      li {
        padding: 5px 10px 5px 15px;
        font-size: 0.9rem;

        &:hover {
          color: var(--main-color);
        }

        i {
          margin: 0 5px;
          font-size: 0.8rem;
        }
      }
    }

    &.showSub {
      .link {
        &>i {
          transform: rotate(180deg);
        }
      }
    }

  }

  &.small {
    position: relative;

    .link {
      padding: 2px 13px;
      text-align: center !important;
      position: relative;
      z-index: 9999999;
      justify-content: center;

      .icon i {
        width: auto;
      }

      &>i {
        opacity: 0;
        display: none;
      }

      .label {
        opacity: 0;
        display: none;
      }
    }

    .subMenu {
      display: none;
    }

    &.expaneded {
      .subMenu {
        display: block;
      }

      .link {
        justify-content: flex-start;

        .icon i {
          width: 40px;
        }

        &>i {
          opacity: 1;
          display: inline-block;
        }

        .label {
          opacity: 1;
          display: inline-block;
        }
      }
    }
  }
}