.settingsNav {
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgb(56 65 74 / 10%);
  ul {
    li {
      padding: 8px 15px;
      cursor: pointer;
      &.active {
        background-color: var(--main-color);
        border-radius: 10px;
        color: #fff;
      }
      span {
        padding: 0 10px;
      }
    }
  }
}