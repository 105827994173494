.chatBottom {
  display: flex;
  position: relative;
  div {
    width: 100%;
    display: flex;
    align-items: center;
    input {
      width: 100%;
      padding: 15px 20px;
      border: none;
      background-color: transparent;
      outline: none;
    }

    i {
      margin: 0 30px;
      font-size: 1.3rem;
      cursor: pointer;
    }
  }

  button {
    padding: 0 20px;
    background-color: var(--main-color);
    border: none;
    color: rgb(230, 230, 230);
    cursor: pointer;
    font-size: 1.3rem;
    outline: none;
    &.ar {
      i {
        transform: rotate(180deg);
      }
    }
  }

  .chatBottom__imagePreview {
    position: absolute;
    top: -250px;
    left: 0%;
    width: 100%;
    height: 250px;
    padding: 10px;
    display: grid;
    place-items: center;
    animation: show 0.7s forwards;
    @keyframes show {
      0% {
        transform: translateY(-30px);
        opacity: 0;
      }
    }
    div {
      background-color: var(--secondary-background);
      width: 250px;
      height: 220px;
      position: relative;
      padding: 10px;
      border-radius: 5px;
      img {
        border-radius: 5px;
      }
      i {
        position: absolute;
        top: 15px;
        right: -10px;
        background-color: rgba(0, 0, 0, 0.5);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: grid;
        place-items: center;
        color: #fff;
        font-size: 0.95rem;
        &:hover {
          background-color: var(--main-color);
        }
      }
    }
  }
}