@import '../../styles/extends';

.createReturn {
  .createReturn__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    h6 {
      font-size: 16px;
      font-weight: 700;
    }
    p {
      span {
        font-weight: 400;
      }
    }
  }

  .createReturn__inputs, .createReturn__returns {
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgb(56 65 74 / 10%);
  }

  .createReturn__inputs {
    .createReturn__inputs_field {
      margin-bottom: 10px;
      label {
        font-size: 0.9rem;
        font-weight: 500;
      }

      .actions {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        div {
          width: 100%
        }

        input {
          @extend %input;
          width: 100%;
        }

        button {
          @extend %btn;
          padding: 5px 10px;
          font-size: 0.85rem;
        }
      }

      &.disabled {
        label {
          opacity: 0.5;
        }
  
        .actions {
          display: flex;
          align-items: center;
  
          input {
            opacity: 0.5;
          }
  
          button {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
      }
    }
  }

  .createReturn__returns {
    p.warning {
      text-align: center !important;
      padding: 20px;
    }
  }
  .btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    button {
      @extend %btn;
    }
  }
}