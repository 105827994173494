@import '../../styles/mixins.scss';

.notifications__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  h6 {
    font-size: 1rem;
    font-weight: 700;
  }
  p {
    span {
      font-weight: 400;
    }
  }
}

.notifications {
  background-color: var(--secondary-background);
  padding: 0px 0;
  border-radius: 5px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.05);

  .head {
    padding: 10px 20px;
    display: flex;
    justify-content: flex-end;
    span {
      font-weight: 600;
      color: var(--main-color);
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .notification {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 20px;
    cursor: pointer;
    margin-bottom: 5px;
    &.unRead {
      background-color: var(--main-color-very-light);
      border-left: 4px solid var(--main-color);
      &.ar {
        border-left: unset;
        border-right: 4px solid var(--main-color);
      }
      &:hover {
        background-color: var(--main-color-light);
      }
    }
    &:hover {
      background-color: var(--hover-color);
    }
    div {
      padding: 0 !important;
    }

    .notification__wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      width: 90%;
      
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        @include xs {
          width: 30px;
          height: 30px;
        }
      }

      h6 {
        margin: 0px;
        @include xs {
          font-size: 0.8rem;
        }
      }

      p {
        font-size: 0.9rem;
        margin: 0px;
        @include xs {
          font-size: 0.8rem;
        }
      }
    }

    i {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: grid;
      place-items: center;
      color: var(--error-alert);
      background-color: var(--error-alert-light);
      margin: 0 !important;
      &:hover {
        background-color: var(--error-alert);
        color: #fff;
      }
      @include xs {
        width: 30px;
        height: 30px;
        font-size: 0.8rem;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    @include xs {
      flex-direction: column;
      align-items: center;
    }
  }
}