// IMPORTING GLOBAL VARIABLES AND MIXINS
@import '../../../styles/mixins';
@import '../../../styles/extends';

.overlay {
  @keyframes showModal {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  animation: showModal 0.2s forwards ease-in-out 1;
  .area {
    background-color: var(--secondary-background);
    width: 500px;
    height: auto;
    max-height: 90%;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    position: relative;
    animation: show 0.3s forwards ease-in-out 1;
    @keyframes show {
      0% {
        transform: translateY(-30px);
        opacity: 0;
      }
      100% {
        transform: translateY(0px);
        opacity: 1;
      }
    }
    .area__wrapper {
      max-height: 90vh;
      padding: 30px;
    }
    @include lg {
      width: 500px;
    }
    @include md {
      width: 500px;
    }
    @include sm {
      width: 500px;
    }
    @include xs {
      width: 90%;
    }
    .close {
      position: absolute;
      top: -15px;
      right: -15px;
      width: 30px;
      height: 30px;
      cursor: pointer;
      background-color: #000;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.2s ease-in-out !important;
      &:hover {
        transform: rotate(90deg) !important;
      }
      &.ar {
        right: unset;
        left: -15px;
      }
    }

    .area__content {
      h3 {
        text-align: center !important;
        margin-bottom: 20px;
        font-size: 1.2rem;
        @include xs {
          font-size: 20px;
        }
      }

      h6 {
        text-align: center !important;
        margin-top: 20px;
      }

      .variants {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        span {
          background-color: var(--default-background);
          padding: 5px 10px;
          border-radius: 5px;
          font-size: 0.9rem;
          font-weight: 500;
          position: relative;
          cursor: pointer;
          i {
            position: absolute;
            top: -10px;
            right: -10px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: var(--main-color);
            color: #fff;
            display: grid;
            place-content: center;
            font-size: 0.7rem;
            cursor: pointer;
            &:hover {
              background-color: var(--hover-color);
              color: #fff;
            }
          }
        }
      }

      .fields {

        .field {
          margin-bottom: 10px;
          label {
            font-size: 0.9rem;
          }
          input {
            display: block;
            @extend %input;
            background-color: var(--default-background);
            width: 100%;
            height: 40px;
          }
          .date {
            fieldset {
              border: none;
            }
            svg {
              color: var((--text-color));
            }
            & > div {
              border: 1px solid var(--border-color);
              padding-right: 10px;
              background-color: var(--default-background);
              border-radius: 5px;
              height: 42px;
              input {
                border: none;
                padding: 0 15px;
                height: 40px;
              }
            }
            &.ar {
              padding-right: 0;
              div {
                padding-right: 0;
              }
            }
          }
        }
        .btn {
          text-align: center !important;
          button {
            @extend %btn;
            padding: 4px 15px;
          }
        }
      }
      .actions {
        display: flex;
        justify-content: flex-end;
        margin-top: 30px;
        button {
          @extend %btn;
          padding: 8px 20px;
        }
        button:first-of-type {
          margin: 0 10px;
          background-color: var(--hover-color);
          border-color: var(--border-color);
          color: var(--text-color);
        }
      }
    }

  }
}