@import '../../../../styles/mixins';
@import '../../../../styles/extends';

.createCategory {
  h3 {
    font-size: 1.1rem;
    margin-bottom: 10px; 
  }
  .createCategory__fields {
    input {
      @extend %input;
      display: block;
      width: 100%;
      margin-bottom: 5px;
    }

    label {
      font-weight: 500;
      font-size: 0.9rem;
    }
  }
    
  .btn {
    display: flex;
    justify-content: flex-end;
    button {
      @extend %btn;
      margin-top: 10px;
      text-align: center !important;
      border-radius: 3px;
      padding: 8px 20px;
    }
  }
}