@import '../../../styles/extends';

.createAd__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  h6 {
    font-size: 16px;
    font-weight: 700;
  }
  p {
    span {
      font-weight: 400;
    }
  }
}

.createAd {
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgb(56 65 74 / 10%);
  h6 {
    margin-bottom: 15px;
    font-size: 1.1rem;
  }

  .createAd__fields {
    .createAd__fields_field {
      display: flex;
      gap: 1rem;
      margin-bottom: 15px;
      @include xs {
        display: block;
      }
      div {
        width: 100%;
        label {
          font-size: 0.9rem;
          font-weight: 500;
        }
        i {
          transform: translateY(1px);
        }
        input {
          padding: 10px 15px;
          width: 100%;
          @extend %input;
        }
        &.single {
          width: calc(50% - 0.5rem);
          @include xs {
            width: 100%;
          }
        }
      }
    }
  }

  label {
    font-size: 0.9rem;
    font-weight: 500;
  }

  .createAd__imageArea {
    height: 250px;
    border: 2px dashed var(--border-color);
    border-radius: 5px;
    margin-top: 10px;
    cursor: pointer;
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      i {
        font-size: 2.5rem;
        text-align: center !important;
      }
      p {
        font-weight: 400;
      }
    }
    .images {
      .image {
        width: 120px;
        height: 120px;
        border-radius: 5px;
        margin-bottom: 10px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
          object-fit: contain;
        }
        i {
          position: absolute;
          top: -20%;
          right: -20%;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-color: var(--default-background);
          font-size: 0.9rem;
          display: grid;
          place-items: center;
          &:hover {
            background-color: var(--main-color);
            color: #fff;
          }
        }
      }
    }
  }
}

.btn {
  display: flex;
  justify-content: flex-end;
  button {
    @extend %btn;
    margin-top: 20px;
    padding: 8px 30px;
  }
}